import { ContentActions, StateActionEnum } from './content-action';
import { IMiniMediaState } from './content-state';

const initialMiniMediaState: IMiniMediaState = {
  folderSelected: undefined,
  folders: undefined,
  medias: undefined,
  mediasOfFolder: undefined,
  mediaDragging: undefined
};
const miniMediaStartupState: any = {
  miniMediaStateOfComponent: initialMiniMediaState
};

export function handleMiniMediaReducer(state: any, action: ContentActions) {
  switch (action.type) {
    case StateActionEnum.SAVE_MINI_MEDIA_STATE:
      return {
        ...state,
        miniMediaStateOfComponent: {
          folderSelected: action.payload.folderSelected,
          folders: action.payload.folders,
          medias: action.payload.medias,
          mediasOfFolder: action.payload.mediasOfFolder,
          mediaDragging: action.payload.mediaDragging
        }
      };
    case StateActionEnum.RESET_MINI_MEDIA_STATE:
      return {
        ...state,
        miniMediaStateOfComponent: initialMiniMediaState
      };
    default:
      return state;
  }
}

export function miniMediaReducer(state = miniMediaStartupState, action: ContentActions) {
  return handleMiniMediaReducer(state, action);
}
