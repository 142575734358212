import { Action } from '@ngrx/store';

export enum StateActionEnum {
  SAVE_MINI_MEDIA_STATE = '[MiniMedia] Save state',
  RESET_MINI_MEDIA_STATE = '[MiniMedia] Reset state'
}

export class SaveMiniMediaStateAction implements Action {
  readonly type = StateActionEnum.SAVE_MINI_MEDIA_STATE;
  constructor(public payload: any) {}
}

export class ResetMiniMediaStateAction implements Action {
  readonly type = StateActionEnum.RESET_MINI_MEDIA_STATE;
  constructor() {}
}

export type ContentActions = SaveMiniMediaStateAction | ResetMiniMediaStateAction;
