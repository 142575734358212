import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import {
  Constant,
  ErrorCodeSimpleMedia,
  FIELD_COMPONENT,
  MODULE_NAME,
  RepeatModeEnum,
  ScreenNameEnum,
  TypeMediaFileEnum,
  TypeMediasNotSupported
} from 'app/config/constants';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogDeliverySimpleComponent } from 'app/dialog/dialog-delivery-simple/dialog-delivery-simple.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogPlaybackTimeComponent } from 'app/dialog/dialog-playback-time/dialog-playback-time.component';
import { DialogPlaylistRecurrenceComponent } from 'app/dialog/dialog-playlist-recurrence/dialog-playlist-recurrence.component';
import { DialogSimpleSignageMessageComponent } from 'app/dialog/dialog-simple-signage-message/dialog-simple-signage-message.component';
import { DialogSimpleSyncSettingComponent } from 'app/dialog/dialog-simple-sync-setting/dialog-simple-sync-setting.component';
import { Common } from 'app/model/entity/common';
import { ContentDay } from 'app/model/entity/content-day';
import { DeviceCalendar } from 'app/model/entity/device-calendar';
import { Folder } from 'app/model/entity/simple/folder';
import { GroupDevice } from 'app/model/entity/simple/group-device';
import { MediaOfSequence } from 'app/model/entity/simple/media-of-sequence';
import { SimpleMedia } from 'app/model/entity/simple/simple-media';
import { SimpleMediaValidator } from 'app/model/entity/simple/simple-media-validator';
import { SimplePlaylist } from 'app/model/entity/simple/simple-playlist';
import { SaveMainStateAction, SaveSimpleSignageEditorStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DeviceService } from 'app/service/device.service';
import { DialogService } from 'app/service/dialog.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { SimpleFolderMediaService } from 'app/service/simple/simple-folder-media.service';
import { SimpleMediaService } from 'app/service/simple/simple-media.service';
import { SimplePlaylistContentDayService } from 'app/service/simple/simple-playlist-content-day.service';
import { SimplePlaylistService } from 'app/service/simple/simple-playlist.service';
import { AppState } from 'app/store/app.state';
import _ from 'lodash';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription, forkJoin } from 'rxjs';
import { ResetMiniMediaStateAction, SaveMiniMediaStateAction } from './ngrx/content-action';

@Component({
  selector: 'simple-signage-editor',
  templateUrl: './simple-signage-editor.component.html',
  styleUrls: ['./simple-signage-editor.component.scss']
})
export class SimpleSignageEditorComponent implements OnInit {
  /**
   * common
   */
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  TypeMediaFileEnum = TypeMediaFileEnum;
  Helper = Helper;
  Constant = Constant;
  /**
   * constants
   */
  readonly MAX_LENGTH_PLAYLIST_NAME = 48;
  readonly TIME_ONE_HOUR = 3600;
  readonly MAX_TOTAL_TIME = 24 * 3600;
  readonly IS_CHOSEN_TAB_PLAYLIST = 'isChosenTabPlaylist';
  readonly IS_EDIT_PLAYLIST = 'isEditPlaylist';
  readonly IS_ON_PREVIEW = 'isOnPreview';
  readonly IS_EDIT_FOLDER = 'isEditFolder';
  readonly IS_DELETE_DISABLE = 'isDeleteDisable';
  readonly FORMAT_DATE = 'YYYY.MM.DD HH:mm';
  readonly FORMAT_TIME = 'HH:mm';
  readonly IMAGE_SEQ_ID = 'image-sequence';
  readonly VIDEO_SEQ_ID = 'video-sequence';
  readonly VIDEO_SIMPLE_ID = 'videoSimple';
  readonly INPUT_IMPORT_ID = 'importedFileSimple';
  readonly IS_DRAGGING_MEDIA = 'isDragging';
  readonly IS_MOVING_CSS = 'isMovingCss';
  readonly MAX_WIDTH_TIME_LINE = 645;
  readonly MP4_TYPE = 'mp4';
  readonly DURATION_ELEMENT = 'duration';
  readonly CURSOR_POINT = 8;
  readonly MAX_NUMBER_COLORS = 5;
  readonly IS_SELECT_FOLDER_ORIGINAL = 'isSelectOriginalFolder';
  readonly IS_SHOW_FOLDER_ORIGINAL = 'isShowOriginalFolder';

  /**
   * access to text box edit playlist name
   */
  @ViewChild('textBoxPlaylist') textBoxRef: ElementRef;
  /**
   * access to text box edit folder name
   */
  @ViewChild('textBoxFolder') folderRef: ElementRef;
  /**
   * true if enlarge preview
   */
  isEnlargePreview: boolean = true;
  /**
   * true if active tab playlist
   */
  isTabPlaylist: boolean;
  /**
   * true if folder, media are selected
   */
  isSelectedMediaOrFolder: boolean;
  /**
   * true if media of sequence is selected
   */
  isSelectedMediaOfSequence: boolean;
  /**
   * true if preview on
   */
  isPreviewOn: boolean;
  /**
   * true if show placeholder
   */
  isShowPlaceholder: boolean;
  /**
   * list playlists
   */
  playlists: Array<SimplePlaylist>;
  /**
   * medias of sequence
   */
  mediaOfSequences: Array<MediaOfSequence> = new Array();
  /**
   * old medias of sequence
   */
  oldMediaOfSequences: Array<MediaOfSequence> = new Array();
  /**
   * list folders
   */
  folders: Array<Folder> = new Array<Folder>();

  /**
   * list media (mini media)
   */
  medias: Array<SimpleMedia>;
  /**
   * medias of folder
   */
  mediasOfFolder: Array<SimpleMedia>;
  /**
   * playlist selected
   */
  playlistSelected: SimplePlaylist;
  /**
   * true if type list
   */
  isTypeList: boolean = true;
  /**
   * folder selected
   */
  folderSelected: Folder;
  /**
   * media selected
   */
  mediaSelected: SimpleMedia;
  /**
   * media of sequence selected
   */
  mediaOfSequenceSelected: MediaOfSequence;
  /**
   * total time
   */
  totalTime: string;

  /**
   * total time
   */
  totalSize: number;

  /**
   * total time video
   */
  totalTimeVideo: string;
  /**
   * config
   */
  config: any;
  /**
   * list point percent
   */
  pointPercents: Array<number>;
  /**
   * list point number
   */
  pointNumbers: Array<number>;
  /**
   * id interval
   */
  idInterval: any;
  /**
   * value cursor point
   */
  valueCursorPoint: number = 0;
  /**
   * time cursor point time line
   */
  timeCursorPointSecond: number = 0;
  /**
   * row height row(mini media)
   */
  heightRowDisplayNone: string;
  /**
   * true if change data
   */
  isChangedData: boolean;
  /**
   * true if change media of sequence
   */
  isChangeMediaOfSequence: boolean;
  /**
   * old medias of folder
   */
  oldMediasOfFolder: Array<SimpleMedia>;
  /**
   * search input value
   */
  searchInputValue: string;
  /**
   * handle button back
   */
  isActiveButtonBack: boolean;
  /**
   * true if dragging media
   */
  isDraggingMedia: boolean;
  /**
   * folder's name contains media dragging
   */
  folderNameContains: string;
  /**
   * true if dragging media of sequence
   */
  isDraggingMediaOfSequence: boolean;
  // ===================TAB CALENDAR===================
  /**
   * true if check all
   */
  isCheckedAll: boolean;
  /**
   * content days month
   */
  contentDaysMonth: Array<ContentDay>;
  /**
   * month selected
   */
  selectedMonth: any;
  /**
   * year selected
   */
  selectedYear: number;
  /**
   * true if > finish month
   */
  isNextMonth: boolean;
  /**
   * true if < start month
   */
  isPreviousMonth: boolean = true;
  /**
   * selected device
   */
  selectedDeviceCalendar: DeviceCalendar;
  /**
   * device is showing calendar
   */
  deviceShowingCalendar: DeviceCalendar;
  /**
   * selected day
   */
  selectedDay: ContentDay;

  folderOriginal: Folder = new Folder();

  /**
   * isOriginalShow
   */
  isOriginalShow: boolean;

  /**
   * isOriginalShow
   */
  isOriginalSelect: boolean;

  /**
   * originalObject
   */
  originalObject: any;

  /**
   * id interval media
   */
  private idIntervalMedia: any;
  /**
   * list month
   */
  listMonth = Array(
    { value: this.translateService.instant('simple-signage-editor.month-1'), key: 0 },
    { value: this.translateService.instant('simple-signage-editor.month-2'), key: 1 },
    { value: this.translateService.instant('simple-signage-editor.month-3'), key: 2 },
    { value: this.translateService.instant('simple-signage-editor.month-4'), key: 3 },
    { value: this.translateService.instant('simple-signage-editor.month-5'), key: 4 },
    { value: this.translateService.instant('simple-signage-editor.month-6'), key: 5 },
    { value: this.translateService.instant('simple-signage-editor.month-7'), key: 6 },
    { value: this.translateService.instant('simple-signage-editor.month-8'), key: 7 },
    { value: this.translateService.instant('simple-signage-editor.month-9'), key: 8 },
    { value: this.translateService.instant('simple-signage-editor.month-10'), key: 9 },
    { value: this.translateService.instant('simple-signage-editor.month-11'), key: 10 },
    { value: this.translateService.instant('simple-signage-editor.month-12'), key: 11 }
  );
  /**
   * list device calendars
   */
  deviceCalendars: Array<DeviceCalendar>;
  /**
   * list group devices
   */
  groupDevices: Array<GroupDevice>;
  /**
   * language key
   */
  languageKey: string;
  /**
   * array subscription
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * save data success
   */
  @Output() saveDataSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * old value playlist name
   */
  oldValuePlaylistName: string;
  /**
   * old value folder name
   */
  oldValueFolderName: string;
  /**
   * colors original
   */
  colorsOriginal = ['#FFB8B8', '#FFEB8D', '#D5FF96', '#B8E9FF', '#D5BAFF'];
  /**
   * colors unused
   */
  unUsedColors: string[];
  /**
   * color being used
   */
  colorBeingUsed: Map<Number, string> = new Map<Number, string>();
  /**
   * list device order like display
   */
  devicesCalendarDisplayOrigin: DeviceCalendar[] = new Array<DeviceCalendar>();
  /**
   * index first device checked in display
   */
  indexFirstDeviceChecked: number;
  /**
   * common object
   */
  private commonObject: Common;
  /**
   * Simple media validator
   */
  private simpleMediaValidator: SimpleMediaValidator;
  /**
   * Error message list
   */
  private errorMessageList = [];
  /**
   * Is unlimited
   */
  private isUnlimited: boolean;
  /**
   * Is unlimited data from dialog data recurrence
   */
  private isUnlimitedDataFromDialogDataRecurrence: boolean;
  /**
   * True if waiting data from API
   */
  private isWaitingActionAPI: boolean;
  /**
   * placeholder search
   */
  placeholderSearch: string;
  /**
   * true if checked expiration
   */
  isCheckedExpiration: boolean = false;
  /**
   * true if checked playback time
   */
  isCheckedPlaybackTime: boolean = false;
  /**
   * config time
   */
  configTime: any;
  /**
   * last minute
   */
  lastMinute: number = 0;
  /**
   * last hour
   */
  lastHour: number = 0;

  /**
   * is cancel sequence
   */
  isCancelSequence: boolean = false;

  /**
   * tmp time null
   */
  tmpTimeNull = [];

  mediaOriginalVideo: Array<string> = [];

  /**
   * stateOfComponent
   */
  stateOfComponent: {
    isChangeLayout: boolean;
    isEnlargePreview: boolean;
    isTabPlaylist: boolean;
    isPreviewOn: boolean;
    isShowPlaceholder: boolean;
    playlists: Array<SimplePlaylist>;
    mediaOfSequences: Array<MediaOfSequence>;
    oldMediaOfSequences: Array<MediaOfSequence>;
    folders: Array<Folder>;
    medias: Array<SimpleMedia>;
    playlistSelected: SimplePlaylist;
    isTypeList: boolean;
    folderSelected: Folder;
    mediaSelected: SimpleMedia;
    mediaOfSequenceSelected: MediaOfSequence;
    totalTime: string;
    totalSize: number;
    mediaOriginalVideo: Array<string>;
    config: any;
    pointPercents: Array<number>;
    pointNumbers: Array<number>;
    isCheckedAll: boolean;
    contentDaysMonth: Array<ContentDay>;
    selectedMonth: any;
    selectedYear: number;
    isNextMonth: boolean;
    isPreviousMonth: boolean;
    selectedDeviceCalendar: any;
    selectedDay: ContentDay;
    deviceCalendars: Array<DeviceCalendar>;
    groupDevices: Array<GroupDevice>;
    mediasOfFolder: Array<SimpleMedia>;
    isChangeMediaOfSequence: boolean;
    oldMediasOfFolder: Array<SimpleMedia>;
    searchInputValue: string;
    isActiveButtonBack: boolean;
    oldValuePlaylistName: string;
    oldValueFolderName: string;
    devicesCalendarDisplayOrigin: DeviceCalendar[];
    timeCursorPointSecond: number;
    colorBeingUsed: Map<Number, string>;
    unUsedColors: string[];
    isSelectedMediaOfSequence: boolean;
    simpleMediaValidator: SimpleMediaValidator;
    totalTimeVideo: string;
    isUnlimited: boolean;
    isUnlimitedDataFromDialogDataRecurrence: boolean;
    isWaitingActionAPI: boolean;
    folderOriginal: Folder;
  };

  /**
   * miniMediaStateOfComponent
   */
  miniMediaStateOfComponent: {
    folderSelected: Folder;
    folders: Array<Folder>;
    medias: Array<SimpleMedia>;
    mediasOfFolder: Array<SimpleMedia>;
    mediaDragging: SimpleMedia;
  };

  lastMinuteTo: number[] = [];
  lastHourTo: number[] = [];
  lastMinuteFrom: number[] = [];
  lastHourFrom: number[] = [];
  constructor(
    private deviceService: DeviceService,
    private menuActionService: MenuActionService,
    private dialogService: DialogService,
    private simplePlaylistService: SimplePlaylistService,
    private simpleMediaService: SimpleMediaService,
    private simpleFolderMediaService: SimpleFolderMediaService,
    private dataService: DataService,
    private translateService: TranslateService,
    public readonly store: Store<AppState>,
    private toast: ToastrService,
    private simplePlaylistContentDay: SimplePlaylistContentDayService,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef
  ) {
    // save data
    this.subscriptions.push(
      this.menuActionService.actionSave.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.SimpleSignageEditorComponent]) {
          if (this.playlistSelected?.isEdit) {
            this.savePlaylist();
          } else if (this.isChangeMediaOfSequence) {
            this.saveSequence(false);
          } else if (this.folderSelected?.isEdit) {
            this.saveFolder();
          }
        }
      })
    );

    // import media
    this.subscriptions.push(
      this.menuActionService.actionImportMedia.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.SimpleSignageEditorComponent]) {
          this.importMedia();
        }
      })
    );

    // add new playlist
    this.subscriptions.push(
      this.menuActionService.actionAdd.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.SimpleSignageEditorComponent]) {
          this.addPlaylist();
        }
      })
    );

    // edit playlist
    this.subscriptions.push(
      this.menuActionService.actionEdit.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.SimpleSignageEditorComponent]) {
          this.editPlaylist();
        }
      })
    );

    // delete playlist
    this.subscriptions.push(
      this.menuActionService.actionDelete.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.SimpleSignageEditorComponent]) {
          this.deletePlaylist();
        }
      })
    );

    // duplicate playlist
    this.subscriptions.push(
      this.menuActionService.actionDuplicate.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.SimpleSignageEditorComponent]) {
          this.duplicatePlaylist();
        }
      })
    );

    // add new folder
    this.subscriptions.push(
      this.menuActionService.actionAddFolder.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.SimpleSignageEditorComponent]) {
          this.addFolder();
        }
      })
    );

    // edit folder name
    this.subscriptions.push(
      this.menuActionService.actionEditFolder.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.SimpleSignageEditorComponent]) {
          this.editFolder();
        }
      })
    );

    // delete item
    this.subscriptions.push(
      this.menuActionService.actionDeleteItem.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.SimpleSignageEditorComponent]) {
          this.deleteItemInMenu();
        }
      })
    );

    // delivery data
    this.subscriptions.push(
      this.menuActionService.actionDelivery.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.SimpleSignageEditorComponent]) {
          this.delivery();
        }
      })
    );

    // sync setting data
    this.subscriptions.push(
      this.menuActionService.actionSimpleSyncSetting.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.SimpleSignageEditorComponent]) {
          this.openDialogSimpleSyncSetting();
        }
      })
    );

    // subscribe for change expiration
    this.subscriptions.push(
      this.menuActionService.actionChangeExpiration.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.SimpleSignageEditorComponent]) {
          this.changeExpiration();
        }
      })
    );

    // subscribe for change playback time
    this.subscriptions.push(
      this.menuActionService.actionChangePlaybackTime.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.SimpleSignageEditorComponent]) {
          this.changePlaybackTime();
        }
      })
    );

    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.SIMPLE_DEVICE_COMPLETED_IDS) {
        if (this.indexFirstDeviceChecked > -1) {
          if (data[1].includes(this.devicesCalendarDisplayOrigin[this.indexFirstDeviceChecked].id)) {
            this.devicesCalendarDisplayOrigin[this.indexFirstDeviceChecked].calendarDays.forEach(contentDay => {
              contentDay.isDelivered = true;
              if (contentDay.playlistSimpleId === -1) {
                contentDay.playlistSimple = undefined;
                contentDay.playlistSimpleId = undefined;
              }
              if (contentDay.unlimitedInfo) {
                contentDay.unlimitedInfo['isDelivered'] = true;
              }
            });
          }
        } else {
          if (data[1].includes(this.selectedDeviceCalendar.id)) {
            this.selectedDeviceCalendar.calendarDays.forEach(contentDay => {
              contentDay.isDelivered = true;
              if (contentDay.playlistSimpleId === -1) {
                contentDay.playlistSimple = undefined;
                contentDay.playlistSimpleId = undefined;
              }
              if (contentDay.unlimitedInfo) {
                contentDay.unlimitedInfo['isDelivered'] = true;
              }
            });
          }
        }
      }
    });

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => {
        // multi language list month
        this.listMonth = Array(
          { value: this.translateService.instant('simple-signage-editor.month-1'), key: 0 },
          { value: this.translateService.instant('simple-signage-editor.month-2'), key: 1 },
          { value: this.translateService.instant('simple-signage-editor.month-3'), key: 2 },
          { value: this.translateService.instant('simple-signage-editor.month-4'), key: 3 },
          { value: this.translateService.instant('simple-signage-editor.month-5'), key: 4 },
          { value: this.translateService.instant('simple-signage-editor.month-6'), key: 5 },
          { value: this.translateService.instant('simple-signage-editor.month-7'), key: 6 },
          { value: this.translateService.instant('simple-signage-editor.month-8'), key: 7 },
          { value: this.translateService.instant('simple-signage-editor.month-9'), key: 8 },
          { value: this.translateService.instant('simple-signage-editor.month-10'), key: 9 },
          { value: this.translateService.instant('simple-signage-editor.month-11'), key: 10 },
          { value: this.translateService.instant('simple-signage-editor.month-12'), key: 11 }
        );
        // multi language date picker
        this.languageKey = this.commonService.getCommonObject().setting?.language;
        setTimeout(() => {
          this.updateConfig();
          Helper.customizeTimePicker(this.languageKey, true);
        });
        // multi language placeholder search
        this.placeholderSearch = this.translateService.instant('simple-signage-editor.search');
      })
    );

    // store state
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: componentState?.simpleSignageEditorState?.stateOfComponent.isChangeLayout,
            isEnlargePreview: componentState?.simpleSignageEditorState?.stateOfComponent.isEnlargePreview,
            isTabPlaylist: componentState?.simpleSignageEditorState?.stateOfComponent.isTabPlaylist,
            isPreviewOn: componentState?.simpleSignageEditorState?.stateOfComponent.isPreviewOn,
            isShowPlaceholder: componentState?.simpleSignageEditorState?.stateOfComponent.isShowPlaceholder,
            playlists: componentState?.simpleSignageEditorState?.stateOfComponent.playlists,
            mediaOfSequences: componentState?.simpleSignageEditorState?.stateOfComponent.mediaOfSequences,
            oldMediaOfSequences: componentState?.simpleSignageEditorState?.stateOfComponent.oldMediaOfSequences,
            folders: componentState?.simpleSignageEditorState?.stateOfComponent.folders,
            medias: componentState?.simpleSignageEditorState?.stateOfComponent.medias,
            playlistSelected: componentState?.simpleSignageEditorState?.stateOfComponent.playlistSelected,
            isTypeList: componentState?.simpleSignageEditorState?.stateOfComponent.isTypeList,
            folderSelected: componentState?.simpleSignageEditorState?.stateOfComponent.folderSelected,
            mediaSelected: componentState?.simpleSignageEditorState?.stateOfComponent.mediaSelected,
            mediaOfSequenceSelected: componentState?.simpleSignageEditorState?.stateOfComponent.mediaOfSequenceSelected,
            totalTime: componentState?.simpleSignageEditorState?.stateOfComponent.totalTime,
            totalSize: componentState?.simpleSignageEditorState?.stateOfComponent.totalSize,
            mediaOriginalVideo: componentState?.simpleSignageEditorState?.stateOfComponent.mediaOriginalVideo,
            config: componentState?.simpleSignageEditorState?.stateOfComponent.config,
            pointPercents: componentState?.simpleSignageEditorState?.stateOfComponent.pointPercents,
            pointNumbers: componentState?.simpleSignageEditorState?.stateOfComponent.pointNumbers,
            isCheckedAll: componentState?.simpleSignageEditorState?.stateOfComponent.isCheckedAll,
            contentDaysMonth: componentState?.simpleSignageEditorState?.stateOfComponent.contentDaysMonth,
            deviceCalendars: componentState?.simpleSignageEditorState?.stateOfComponent.deviceCalendars,
            selectedMonth: componentState?.simpleSignageEditorState?.stateOfComponent.selectedMonth,
            selectedYear: componentState?.simpleSignageEditorState?.stateOfComponent.selectedYear,
            isNextMonth: componentState?.simpleSignageEditorState?.stateOfComponent.isNextMonth,
            isPreviousMonth: componentState?.simpleSignageEditorState?.stateOfComponent.isPreviousMonth,
            selectedDeviceCalendar: componentState?.simpleSignageEditorState?.stateOfComponent.selectedDeviceCalendar,
            groupDevices: componentState?.simpleSignageEditorState?.stateOfComponent.groupDevices,
            selectedDay: componentState?.simpleSignageEditorState?.stateOfComponent.selectedDay,
            mediasOfFolder: componentState?.simpleSignageEditorState?.stateOfComponent.mediasOfFolder,
            isChangeMediaOfSequence: componentState?.simpleSignageEditorState?.stateOfComponent.isChangeMediaOfSequence,
            oldMediasOfFolder: componentState?.simpleSignageEditorState?.stateOfComponent.oldMediasOfFolder,
            searchInputValue: componentState?.simpleSignageEditorState?.stateOfComponent.searchInputValue,
            isActiveButtonBack: componentState?.simpleSignageEditorState?.stateOfComponent.isActiveButtonBack,
            oldValuePlaylistName: componentState?.simpleSignageEditorState?.stateOfComponent.oldValuePlaylistName,
            oldValueFolderName: componentState?.simpleSignageEditorState?.stateOfComponent.oldValueFolderName,
            devicesCalendarDisplayOrigin: componentState?.simpleSignageEditorState?.stateOfComponent.devicesCalendarDisplayOrigin,
            timeCursorPointSecond: componentState?.simpleSignageEditorState?.stateOfComponent.timeCursorPointSecond,
            colorBeingUsed: componentState?.simpleSignageEditorState?.stateOfComponent.colorBeingUsed,
            unUsedColors: componentState?.simpleSignageEditorState?.stateOfComponent.unUsedColors,
            isSelectedMediaOfSequence: componentState?.simpleSignageEditorState?.stateOfComponent.isSelectedMediaOfSequence,
            simpleMediaValidator: componentState?.simpleSignageEditorState?.stateOfComponent.simpleMediaValidator,
            totalTimeVideo: componentState?.simpleSignageEditorState?.stateOfComponent.totalTimeVideo,
            isUnlimited: componentState?.simpleSignageEditorState?.stateOfComponent.isUnlimited,
            isUnlimitedDataFromDialogDataRecurrence:
              componentState?.simpleSignageEditorState?.stateOfComponent.isUnlimitedDataFromDialogDataRecurrence,
            isWaitingActionAPI: componentState?.simpleSignageEditorState?.stateOfComponent.isWaitingActionAPI,
            folderOriginal: componentState?.simpleSignageEditorState?.stateOfComponent.folderOriginal
          };
        })
    );

    // store state
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.miniMediaStateOfComponent = {
            folderSelected: componentState?.miniMediaState?.miniMediaStateOfComponent.folderSelected,
            folders: componentState?.miniMediaState?.miniMediaStateOfComponent.folders,
            medias: componentState?.miniMediaState?.miniMediaStateOfComponent.medias,
            mediasOfFolder: componentState?.miniMediaState?.miniMediaStateOfComponent.mediasOfFolder,
            mediaDragging: componentState?.miniMediaState?.miniMediaStateOfComponent.mediaDragging
          };
        })
    );

    this.commonObject = this.commonService.getCommonObject();
  }

  async ngOnInit(): Promise<void> {
    this.isCheckedExpiration = this.commonObject.isCheckedExpiration ?? this.isCheckedExpiration;
    this.isCheckedPlaybackTime = this.commonObject.isCheckedPlaybackTime ?? this.isCheckedPlaybackTime;
    this.languageKey = this.commonObject?.setting?.language;
    this.placeholderSearch = this.translateService.instant('simple-signage-editor.search');
    this.sendTimezoneToBack();
    if (!this.stateOfComponent?.isChangeLayout) {
      this.isTabPlaylist = this.commonObject?.isTabPlaylist === undefined ? true : this.commonObject.isTabPlaylist;
      this.dataService.sendData([this.IS_CHOSEN_TAB_PLAYLIST, !this.isTabPlaylist]);
      this.setOriginalFolder();
      await this.confirmOriginalMedia();
      this.getAllDataSimple();
      setTimeout(() => {
        this.updateConfig();
      });
    } else {
      this.handleAfterChangeLayout();
      this.sendTimezoneToBack();
    }
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_CHANGE_TIME_ZONE) {
        if (data[1]) {
          this.sendTimezoneToBack();
        }
      }
    });
  }

  /**
   * confirmOriginalMedia
   */
  private async confirmOriginalMedia(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.simpleMediaService.getObjectOriginal().subscribe(
        data => {
          if (!data) {
            resolve();
            return;
          }
          this.originalObject = data;
          for (const item in this.originalObject) {
            if (this.originalObject[item].type == 'video') {
              this.mediaOriginalVideo.push(this.originalObject[item].name);
            }
          }
          resolve();
        },
        error => reject()
      );
    });
  }
  /**
   * get all data simple
   */
  private getAllDataSimple(): void {
    forkJoin([this.getAllPlaylists(), this.getDataMiniMedia(), this.getAllDeviceCalendars(), this.getSimpleMediaValidator()]);
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    clearInterval(this.idInterval);
    clearInterval(this.idIntervalMedia);
    this.clearPreview();
    this.store.dispatch(
      new SaveSimpleSignageEditorStateAction({
        isChangeLayout: true,
        isEnlargePreview: this.isEnlargePreview,
        isTabPlaylist: this.isTabPlaylist,
        isPreviewOn: this.isPreviewOn,
        isShowPlaceholder: this.isShowPlaceholder,
        playlists: this.playlists,
        mediaOfSequences: this.mediaOfSequences,
        oldMediaOfSequences: this.oldMediaOfSequences,
        folders: this.folders,
        medias: this.medias,
        playlistSelected: this.playlistSelected,
        isTypeList: this.isTypeList,
        folderSelected: this.folderSelected,
        mediaSelected: this.mediaSelected,
        mediaOfSequenceSelected: this.mediaOfSequenceSelected,
        totalTime: this.totalTime,
        totalSize: this.totalSize,
        mediaOriginalVideo: this.mediaOriginalVideo,
        config: this.config,
        pointPercents: this.pointPercents,
        pointNumbers: this.pointNumbers,
        isCheckedAll: this.isCheckedAll,
        contentDaysMonth: this.contentDaysMonth,
        selectedMonth: this.selectedMonth,
        selectedYear: this.selectedYear,
        isNextMonth: this.isNextMonth,
        isPreviousMonth: this.isPreviousMonth,
        selectedDeviceCalendar: this.selectedDeviceCalendar,
        selectedDay: this.selectedDay,
        deviceCalendars: this.deviceCalendars,
        groupDevices: this.groupDevices,
        mediasOfFolder: this.mediasOfFolder,
        isChangeMediaOfSequence: !this.checkDiffDataMediaOfSequences(),
        oldMediasOfFolder: this.oldMediasOfFolder,
        searchInputValue: this.searchInputValue,
        isActiveButtonBack: this.isActiveButtonBack,
        oldValuePlaylistName: this.oldValuePlaylistName,
        oldValueFolderName: this.oldValueFolderName,
        devicesCalendarDisplayOrigin: this.devicesCalendarDisplayOrigin,
        timeCursorPointSecond: this.timeCursorPointSecond,
        colorBeingUsed: this.colorBeingUsed,
        unUsedColors: this.unUsedColors,
        isSelectedMediaOfSequence: this.isSelectedMediaOfSequence,
        simpleMediaValidator: this.simpleMediaValidator,
        totalTimeVideo: this.totalTimeVideo,
        isUnlimited: this.isUnlimited,
        isUnlimitedDataFromDialogDataRecurrence: this.isUnlimitedDataFromDialogDataRecurrence,
        isWaitingActionAPI: this.isWaitingActionAPI,
        folderOriginal: this.folderOriginal
      })
    );
    delete this.commonObject.isTabPlaylist;
  }

  /**
   * handle after change layout
   */
  public handleAfterChangeLayout(): void {
    this.isEnlargePreview = this.stateOfComponent?.isEnlargePreview;
    this.isTabPlaylist = this.stateOfComponent?.isTabPlaylist;
    this.isPreviewOn = this.stateOfComponent?.isPreviewOn;
    this.isShowPlaceholder = this.stateOfComponent?.isShowPlaceholder;
    this.playlists = this.stateOfComponent?.playlists;
    this.mediaOfSequences = this.stateOfComponent?.mediaOfSequences;
    this.oldMediaOfSequences = this.stateOfComponent?.oldMediaOfSequences;
    this.folders = this.stateOfComponent?.folders;
    this.medias = this.stateOfComponent?.medias;
    this.playlistSelected = this.stateOfComponent?.playlistSelected;
    this.isTypeList = this.stateOfComponent?.isTypeList;
    this.folderSelected = this.stateOfComponent?.folderSelected;
    this.mediaSelected = this.stateOfComponent?.mediaSelected;
    this.mediaOfSequenceSelected = this.stateOfComponent?.mediaOfSequenceSelected;
    this.totalTime = this.stateOfComponent?.totalTime;
    this.totalSize = this.stateOfComponent?.totalSize;
    this.mediaOriginalVideo = this.stateOfComponent?.mediaOriginalVideo;
    this.config = this.stateOfComponent?.config;
    this.pointPercents = this.stateOfComponent?.pointPercents;
    this.pointNumbers = this.stateOfComponent?.pointNumbers;
    this.isCheckedAll = this.stateOfComponent?.isCheckedAll;
    this.contentDaysMonth = this.stateOfComponent?.contentDaysMonth;
    this.deviceCalendars = this.stateOfComponent?.deviceCalendars;
    this.selectedMonth = this.stateOfComponent?.selectedMonth;
    this.selectedYear = this.stateOfComponent?.selectedYear;
    this.isNextMonth = this.stateOfComponent?.isNextMonth;
    this.isPreviousMonth = this.stateOfComponent?.isPreviousMonth;
    this.selectedDeviceCalendar = this.stateOfComponent?.selectedDeviceCalendar;
    this.groupDevices = this.stateOfComponent?.groupDevices;
    this.selectedDay = this.stateOfComponent?.selectedDay;
    this.mediasOfFolder = this.stateOfComponent?.mediasOfFolder;
    this.isChangeMediaOfSequence = this.stateOfComponent?.isChangeMediaOfSequence;
    this.oldMediasOfFolder = this.stateOfComponent?.oldMediasOfFolder;
    this.searchInputValue = this.stateOfComponent?.searchInputValue;
    this.isActiveButtonBack = this.stateOfComponent?.isActiveButtonBack;
    this.oldValuePlaylistName = this.stateOfComponent?.oldValuePlaylistName;
    this.oldValueFolderName = this.stateOfComponent?.oldValueFolderName;
    this.devicesCalendarDisplayOrigin = this.stateOfComponent?.devicesCalendarDisplayOrigin;
    this.timeCursorPointSecond = this.stateOfComponent?.timeCursorPointSecond;
    this.colorBeingUsed = this.stateOfComponent?.colorBeingUsed;
    this.unUsedColors = this.stateOfComponent?.unUsedColors;
    this.isSelectedMediaOfSequence = this.stateOfComponent?.isSelectedMediaOfSequence;
    this.simpleMediaValidator = this.stateOfComponent?.simpleMediaValidator;
    this.totalTimeVideo = this.stateOfComponent?.totalTimeVideo;
    this.isUnlimited = this.stateOfComponent?.isUnlimited;
    this.isUnlimitedDataFromDialogDataRecurrence = this.stateOfComponent?.isUnlimitedDataFromDialogDataRecurrence;
    this.isWaitingActionAPI = this.stateOfComponent?.isWaitingActionAPI;
    this.folderOriginal = this.stateOfComponent?.folderOriginal;

    this.calculateRowHeight(this.mediasOfFolder ? this.mediasOfFolder.length : this.medias.length + this.folders.length);
    this.dataService.sendData([this.IS_CHOSEN_TAB_PLAYLIST, !this.isTabPlaylist]);
    this.dataService.sendData([this.IS_EDIT_PLAYLIST, this.playlistSelected?.isEdit]);
    this.dataService.sendData([this.IS_EDIT_FOLDER, this.folderSelected?.isEdit]);
    this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedMediaOfSequence]);
    this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
    this.commonObject.isTabPlaylist = this.isTabPlaylist;
    Helper.saveMainStateAction(this.store, this.commonObject);
    if (this.isTabPlaylist && (this.mediaOfSequenceSelected || this.mediaSelected)) {
      if (this.mediaOfSequenceSelected) {
        this.resetCursorPoint();
      }
      setTimeout(() => {
        this.redrawMedia(this.isTabPlaylist);
      });
    }
  }

  /**
   * get all playlists
   */
  private getAllPlaylists(): void {
    this.simplePlaylistService.getAllPlaylists().subscribe(data => {
      this.playlists = Helper.convertDataSimplePlaylists(data);
      if (this.playlists.length) {
        this.selectPlaylist(this.playlists[0]);
      }
    });
  }

  /**
   * get all medias
   */
  private getAllMedias(): void {
    this.simpleMediaService.getMediasByFolderId(null).subscribe(data => {
      this.medias = Helper.convertDataSimpleMedias(data, true).filter(media => !TypeMediasNotSupported.includes(media.type));
      this.calculateRowHeight(this.folders.length + this.medias.length);
    });
  }

  /**
   * get all data folder and media in mini media
   */
  public async getDataMiniMedia(): Promise<void> {
    // get all folder medias
    this.simpleFolderMediaService.getAllFolderMedias().subscribe(data => {
      this.folders = Helper.convertDataSimpleFolderMedias(data);
      if (this.originalObject) {
        this.folders.push(this.folderOriginal);
      }
      // get all medias
      this.getAllMedias();
    });
  }

  /**
   * getFileOriginalMedia
   */
  private getFileOriginalMedia(): void {
    this.simpleMediaService.getMediasForOriginalFolder(this.folderOriginal.id).subscribe(
      data => {
        this.isOriginalShow = true;
        this.dataService.sendData([this.IS_SHOW_FOLDER_ORIGINAL, this.isOriginalShow]);
        this.handleAfterGetMediasOfFolder(data);
        if (!data.length) {
          this.isSelectedMediaOrFolder = false;
          this.isSelectedMediaOfSequence = false;
          this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedMediaOfSequence]);
        }
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * setOriginalFolder
   */
  private setOriginalFolder(): void {
    let ids = this.folders.map(folder => folder.id);
    this.folderOriginal.id = Math.floor(Math.random() * 10000000) + 1;
    while (ids.includes(this.folderOriginal.id)) {
      this.folderOriginal.id = Math.floor(Math.random() * 10000000) + 1;
    }
    this.folderOriginal.name = Constant.NAME_FOLDER_ORIGINAL;
  }

  /**
   * Get simple media validator
   */
  private getSimpleMediaValidator(): void {
    this.simpleMediaService.getSimpleMediaValidator().subscribe(data => {
      this.simpleMediaValidator = data;
    });
  }

  /**
   * save changed data when switching to Calendar tab
   * @returns
   */
  saveTabPlaylist(): void {
    if (!this.validatePlaylist() || !this.validateSequence() || !this.validateFolder()) {
      return;
    }
    forkJoin({
      checkExistPlaylist: this.simplePlaylistService.checkExistPlaylist(this.playlistSelected.name, this.playlistSelected.id),
      checkExistFolder: this.simpleFolderMediaService.checkExistFolder(this.folderSelected?.name, this.folderSelected?.id)
    }).subscribe(data => {
      if (!data) {
        return;
      }
      if (data.checkExistPlaylist) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('simple-signage-editor.error'),
            text: this.translateService.instant('simple-signage-editor.exists-pl')
          }
        });
        return;
      }
      if (data.checkExistFolder) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('simple-signage-editor.error'),
            text: this.translateService.instant('simple-signage-editor.exists-folder')
          }
        });
        return;
      }

      if (this.playlistSelected?.isEdit) {
        // get information to save data
        let playlistToSave = _.cloneDeep(this.playlistSelected);
        if (playlistToSave.id) {
          playlistToSave.sequence = this.handleSequenceBeforeSave();
        }
        this.simplePlaylistService.save(playlistToSave).subscribe(data => {
          this.handleAfterSavePlaylist(data, playlistToSave);
        });
      } else if (this.isChangeMediaOfSequence) {
        // get information to save data
        let playlist = _.cloneDeep(this.playlistSelected);
        playlist.sequence = this.handleSequenceBeforeSave();
        this.simplePlaylistService.save(playlist).subscribe(data => {
          this.handleAfterSaveSequence(playlist);
        });
      }

      if (this.folderSelected?.isEdit) {
        // get information folder to save data
        let folderToSave = _.cloneDeep(this.folderSelected);
        this.simpleFolderMediaService.save(folderToSave).subscribe(data => {
          this.handleAfterSaveFolder(data, folderToSave);
        });
      }
      this.switchTab();
    });
  }

  /**
   * handle after save Playlist
   *
   * @param playlistSaved
   * @param playlistEdit
   * @returns
   */
  private handleAfterSavePlaylist(playlistSaved: SimplePlaylist, playlistEdit: SimplePlaylist): void {
    this.isChangedData = false;
    this.isWaitingActionAPI = false;
    this.dataService.sendData([this.IS_EDIT_PLAYLIST, false]);
    // add playlist
    if (!playlistEdit.id) {
      this.playlists[this.playlists.length - 1] = playlistSaved;
      this.playlistSelected = this.playlists[this.playlists.length - 1];
    } else {
      // edit playlist
      const index = this.playlists.findIndex(playlist => playlist.id == this.playlistSelected.id);
      this.playlists[index] = playlistSaved;
      this.playlistSelected = this.playlists[index];
      if (this.oldValuePlaylistName !== this.playlistSelected.name) {
        this.deviceShowingCalendar?.calendarDays
          .filter(contentDay => contentDay.playlistSimple?.name === this.oldValuePlaylistName)
          .map(contentDayData => {
            contentDayData.playlistSimple.name = this.playlistSelected.name;
          });
      }
    }
    this.playlistSelected.isEdit = false;
    this.selectPlaylist(this.playlistSelected);
  }

  /**
   * handle after save Sequence
   *
   * @param playlist
   * @returns
   */
  private handleAfterSaveSequence(playlist: SimplePlaylist): void {
    const index = this.playlists.findIndex(data => data.id == playlist.id);
    if (index == -1) {
      return;
    }
    this.mediaOfSequences = null;
    //this.playlistSelected.isEdit = false;
    this.selectPlaylist(this.playlists[index]);
  }

  /**
   * handle after save Folder
   *
   * @param folderSaved
   * @param folderEdit
   * @returns
   */
  private handleAfterSaveFolder(folderSaved: Folder, folderEdit: Folder): void {
    this.isChangedData = false;
    this.isWaitingActionAPI = false;
    // add folder
    this.dataService.sendData([this.IS_EDIT_FOLDER, false]);
    if (!folderEdit.id) {
      this.folders[this.folders.length - 1] = folderSaved;
      this.folderSelected = this.folders[this.folders.length - 1];
    } else {
      //  edit folder
      const index = this.folders.findIndex(folder => folder.id == this.folderSelected.id);
      this.folders[index] = folderSaved;
      this.folderSelected = this.folders[index];
    }
    this.folderSelected.isEdit = false;
    this.selectFolder(this.folderSelected);
  }

  /**
   * choose tab
   */
  public chooseTab(): void {
    // confirm save if change data
    this.isChangeMediaOfSequence = !this.checkDiffDataMediaOfSequences();
    if ((this.playlistSelected?.isEdit || this.isChangeMediaOfSequence || this.folderSelected?.isEdit) && this.isTabPlaylist) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('simple-signage-editor.save-changes'),
            button1: this.translateService.instant('simple-signage-editor.yes'),
            button2: this.translateService.instant('simple-signage-editor.no')
          }
        },
        result => {
          if (result) {
            this.saveTabPlaylist();
          } else {
            if (this.playlistSelected?.isEdit) {
              this.cancelAddPlaylist();
            }
            if (this.isChangeMediaOfSequence) {
              this.cancelSequence();
            }
            if (this.folderSelected?.isEdit) {
              this.cancelAddFolder();
            }
            this.switchTab();
          }
        }
      );
    } else {
      this.cancelSequence();
      this.switchTab();
    }
  }

  /**
   * switch tab
   */
  private switchTab(): void {
    this.isTabPlaylist = !this.isTabPlaylist;
    this.commonObject.isTabPlaylist = this.isTabPlaylist;
    Helper.saveMainStateAction(this.store, this.commonObject);
    // redraw preview
    this.redrawMedia(this.isTabPlaylist);
    // pause preview if open tab calendar
    if (!this.isTabPlaylist && this.isPreviewOn) {
      this.previewMedia(false);
    }
    this.dataService.sendData([this.IS_CHOSEN_TAB_PLAYLIST, !this.isTabPlaylist]);
    this.isChangeMediaOfSequence = false;
  }

  /**
   * check diff data media of sequence
   * @returns
   */
  private checkDiffDataMediaOfSequences(): boolean {
    let oldData = Helper.convertDataToCheckDiffData(this.oldMediaOfSequences);
    let mediaOfSequencesnew = _.cloneDeep(!this.mediaOfSequences ? new Array<MediaOfSequence>() : this.mediaOfSequences);
    let newData = Helper.convertDataToCheckDiffData(mediaOfSequencesnew);
    // return true if equals
    return JSON.stringify(oldData) == JSON.stringify(newData);
  }

  /**
   * select playlist
   * @param playlist
   * @param isCheckDiffData
   * @returns
   */
  public selectPlaylist(playlist: SimplePlaylist, isCheckDiffData?: boolean, event?: any): void {
    if (this.playlistSelected?.isEdit || this.isPreviewOn || event?.target?.id === 'deletePlaylist') {
      return;
    }

    // confirm save if change data
    this.isChangeMediaOfSequence = isCheckDiffData && playlist.id != this.playlistSelected?.id && !this.checkDiffDataMediaOfSequences();
    if (this.isChangeMediaOfSequence) {
      this.isChangedData = true;
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('simple-signage-editor.save-changes'),
            button1: this.translateService.instant('simple-signage-editor.yes'),
            button2: this.translateService.instant('simple-signage-editor.no')
          }
        },
        result => {
          this.isChangedData = false;
          if (result) {
            this.saveSequence(true);
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.mediaOfSequences = null;
                this.selectPlaylist(playlist);
              }
            });
          } else {
            this.mediaOfSequences = null;
            this.selectPlaylist(playlist);
          }
        }
      );
    } else {
      this.playlistSelected = playlist;
      // get sequence of playlist
      this.simplePlaylistService.getSequenceOfPlaylist(this.playlistSelected.id).subscribe(
        data => {
          this.mediaOfSequences = Helper.convertDataListMediaOfSequence(data);
          this.handleDuration();
          this.calculatorPointList();
          this.oldMediaOfSequences = _.cloneDeep(!this.mediaOfSequences ? new Array<MediaOfSequence>() : this.mediaOfSequences);
          this.selectMediaOfSequence(this.mediaOfSequences[0]);
        },
        error => Helper.handleError(error, this.translateService, this.dialogService)
      );
    }
  }

  /**
   * add playlist
   * @returns
   */
  public addPlaylist(): void {
    if (this.playlistSelected?.isEdit || this.isPreviewOn) {
      return;
    }
    let playlist = new SimplePlaylist(null, '');
    this.playlists.push(playlist);
    this.playlistSelected = this.playlists[this.playlists.length - 1];
    this.playlistSelected.isEdit = true;
    this.mediaOfSequences = [];
    this.totalSize = 0;
    this.totalTime = '';
    this.dataService.sendData([this.IS_EDIT_PLAYLIST, this.playlistSelected?.isEdit]);
    this.isChangedData = true;
    // clear preview
    this.clearPreview();
    clearInterval(this.idInterval);
    clearInterval(this.idIntervalMedia);
  }

  /**
   * edit playlist
   * @returns
   */
  private editPlaylist(): void {
    if (!this.playlistSelected || !this.playlists?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('simple-signage-editor.error'),
          text: this.translateService.instant('simple-signage-editor.select-pl')
        }
      });
      return;
    } else if (this.playlistSelected.isEdit || this.isPreviewOn) {
      return;
    }
    this.playlistSelected.isEdit = true;
    this.oldValuePlaylistName = this.playlistSelected.name;
    this.dataService.sendData([this.IS_EDIT_PLAYLIST, this.playlistSelected?.isEdit]);
    this.isChangedData = true;
  }

  /**
   * save playlist
   *
   */
  public savePlaylist(): void {
    if (this.isPreviewOn || this.isWaitingActionAPI) {
      return;
    }
    this.isWaitingActionAPI = true;
    // validate playlist
    if (!this.validatePlaylist()) {
      this.saveDataSuccess.emit(false);
      this.isWaitingActionAPI = false;
      return;
    }
    // validate exists playlist
    this.simplePlaylistService.checkExistPlaylist(this.playlistSelected.name, this.playlistSelected.id).subscribe(
      data => {
        if (data) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('simple-signage-editor.error'),
              text: this.translateService.instant('simple-signage-editor.exists-pl')
            }
          });
          this.isWaitingActionAPI = false;
          return;
        }
        // get information to save data
        let playlistToSave = _.cloneDeep(this.playlistSelected);
        playlistToSave.name = playlistToSave.name;
        if (playlistToSave.id) {
          playlistToSave.sequence = this.handleSequenceBeforeSave();
        }
        // save data
        this.simplePlaylistService.save(playlistToSave).subscribe(
          data => {
            this.saveDataSuccess.emit(true);
            this.handleAfterSavePlaylist(data, playlistToSave);
          },
          error => {
            this.isWaitingActionAPI = false;
            this.saveDataSuccess.emit(false);
            this.handleErrorSaveData(error, this.translateService.instant('simple-signage-editor.exists-pl'));
          }
        );
      },
      error => {
        this.isWaitingActionAPI = false;
        this.saveDataSuccess.emit(false);
        Helper.handleError(error, this.translateService, this.dialogService);
      }
    );
  }

  /**
   * validate playlist
   * @returns
   */
  private validatePlaylist(): boolean {
    // validate empty
    if (this.playlistSelected.name.trim() == Constant.EMPTY) {
      this.textBoxRef?.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('simple-signage-editor.error'),
          text: this.translateService.instant('simple-signage-editor.empty-pl')
        }
      });
      return false;
      // validate max length
    } else if (this.playlistSelected.name.length > this.MAX_LENGTH_PLAYLIST_NAME) {
      this.textBoxRef?.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('simple-signage-editor.error'),
          text: Helper.formatString(this.translateService.instant('simple-signage-editor.length-pl'), `${this.MAX_LENGTH_PLAYLIST_NAME}`)
        }
      });
      return false;
    }
    return true;
  }

  /**
   * delete playlist
   * @returns
   */
  private deletePlaylist(): void {
    if (this.playlistSelected?.isEdit || this.isPreviewOn) {
      return;
    }
    if (!this.playlistSelected || !this.playlists?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('simple-signage-editor.error'),
          text: this.translateService.instant('simple-signage-editor.select-pl')
        }
      });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: Helper.formatString(this.translateService.instant('simple-signage-editor.want-delete'), this.playlistSelected.name),
          button1: this.translateService.instant('simple-signage-editor.yes'),
          button2: this.translateService.instant('simple-signage-editor.no')
        }
      },
      result => {
        if (result) {
          this.simplePlaylistService.deletePlaylist(this.playlistSelected.id).subscribe(
            () => {
              let selectedIndex = this.playlists.findIndex(playlist => playlist.id == this.playlistSelected.id);
              if (selectedIndex == -1) {
                return;
              }
              this.playlists.splice(selectedIndex, 1);
              this.colorBeingUsed?.delete(this.playlistSelected.id);
              let colorDelete;
              // change status nashi of device
              let playlistInfo = this.deviceShowingCalendar?.contentDays.filter(contentDayShowing => contentDayShowing.unlimitedInfo)[0];
              if (playlistInfo && this.playlistSelected.id == playlistInfo.unlimitedInfo['playlistSimpleId']) {
                this.isUnlimited = false;
              }
              // set data after delete simple playlist
              this.contentDaysMonth.filter(contentDay => contentDay.playlistSimple != this.playlistSelected);
              if (this.indexFirstDeviceChecked > -1) {
                this.devicesCalendarDisplayOrigin[this.indexFirstDeviceChecked].contentDays = this.devicesCalendarDisplayOrigin[
                  this.indexFirstDeviceChecked
                ].contentDays.filter(contentDay => contentDay.playlistSimpleId != this.playlistSelected.id);
                if (!this.isUnlimited) {
                  this.devicesCalendarDisplayOrigin[this.indexFirstDeviceChecked].contentDays.forEach(data => {
                    data.unlimitedInfo = undefined;
                  });
                }
              } else if (this.selectedDeviceCalendar) {
                this.selectedDeviceCalendar.contentDays = this.selectedDeviceCalendar.contentDays.filter(
                  contentDay => contentDay.playlistSimpleId != this.playlistSelected.id
                );
                if (!this.isUnlimited) {
                  this.selectedDeviceCalendar.contentDays.forEach(data => {
                    data.unlimitedInfo = undefined;
                  });
                }
              }
              this.deviceShowingCalendar?.calendarDays.forEach(contentDay => {
                if (contentDay?.playlistSimple?.id !== this.playlistSelected.id) {
                  return;
                }
                if (!colorDelete) {
                  colorDelete = contentDay.color;
                }
                contentDay.playlistSimple = undefined;
                contentDay.color = undefined;
                contentDay.isDelivered = true;
                contentDay.playlistSimpleId = undefined;
              });
              if (colorDelete) {
                this.unUsedColors?.unshift(colorDelete);
              }
              this.mediaOfSequences = [];
              this.oldMediaOfSequences = [];
              this.playlistSelected = undefined;
              if (this.playlists?.length) {
                this.selectPlaylist(this.playlists[0]);
              }
            },
            error => Helper.handleError(error, this.translateService, this.dialogService)
          );
        }
      }
    );
  }

  /**
   * duplicate playlist
   * @returns
   */
  private duplicatePlaylist(): void {
    if (this.playlistSelected?.isEdit || this.isPreviewOn) {
      return;
    }
    if (!this.playlistSelected || !this.playlists?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('simple-signage-editor.error'),
          text: this.translateService.instant('simple-signage-editor.select-pl')
        }
      });
      return;
    }
    this.simplePlaylistService.duplicatePlaylist(this.playlistSelected).subscribe(
      data => {
        if (!data) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('simple-signage-editor.error'),
              text: Helper.formatString(
                this.translateService.instant('simple-signage-editor.length-pl'),
                `${this.MAX_LENGTH_PLAYLIST_NAME}`
              )
            }
          });
          return;
        }
        if (!data.id) {
          return;
        }
        this.playlists.push(Helper.convertDataSimplePlaylist(data));
        this.selectPlaylist(this.playlists[this.playlists.length - 1]);
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * import media
   */
  private importMedia(): void {
    // search => prevent import data
    if (this.searchInputValue?.length) {
      return;
    }
    let element = document.getElementById(this.INPUT_IMPORT_ID) as HTMLInputElement;
    element.setAttribute('accept', '.jpg, .png, .bmp, .mp4');
    element.click();
  }

  /**
   * upload media files
   * @param event
   */
  async upload(event: any): Promise<void> {
    let selectedFiles: any[] = event.target.files;
    if (!selectedFiles || selectedFiles.length <= 0) {
      return;
    }
    this.errorMessageList = await this.validateSimpleMediaInFrontEnd(selectedFiles);
    if (this.errorMessageList.length > 0) {
      this.dialogService.showDialog(DialogSimpleSignageMessageComponent, {
        data: {
          title: this.translateService.instant('simple-signage-editor.error'),
          texts: this.errorMessageList
        }
      });
    } else {
      // upload file
      for (const file of selectedFiles) {
        await this.addNewSimpleMedia(file);
      }
      if (this.errorMessageList.length > 0) {
        this.dialogService.showDialog(DialogSimpleSignageMessageComponent, {
          data: {
            title: this.translateService.instant('simple-signage-editor.error'),
            texts: this.errorMessageList
          }
        });
      }
    }
    this.refreshInputFile();
  }

  /**
   * refresh input file
   */
  private refreshInputFile(): void {
    // reset input file value
    let element = document.getElementById('importedFileSimple') as HTMLInputElement;
    element.value = null;
    this.errorMessageList = [];
  }

  /**
   * Add new simple media
   *
   * @param file file
   */
  private async addNewSimpleMedia(file: any): Promise<void> {
    const folder = this.miniMediaStateOfComponent?.folderSelected;
    await this.simpleMediaService
      .addNewSimpleMedia(file, folder?.id ?? null)
      .toPromise()
      .then(
        data => {
          if (!data) {
            this.errorMessageList.push(
              Helper.formatString(this.translateService.instant(`simple-signage-editor.other-error`), file[Constant.NAME_ELEMENT])
            );
            return;
          }
          let errMessage = this.getErrorMessageFromBackWard(data, file);
          if (errMessage) {
            this.errorMessageList.push(errMessage);
            return;
          }
          const media = Helper.convertDataSimpleMedia(data, true);
          if (this.mediasOfFolder) {
            this.mediasOfFolder.push(media);
            this.oldMediasOfFolder = _.cloneDeep(this.mediasOfFolder);
          } else {
            this.medias.push(media);
          }
          this.calculateRowHeight(this.folders.length + this.medias.length);
        },
        error => {
          if (error.status == Constant.NETWORK_ERROR_CODE) {
            if (!this.errorMessageList.includes(this.translateService.instant('dialog-error.error-network'))) {
              this.errorMessageList.push(this.translateService.instant('dialog-error.error-network'));
            }
            return;
          } else if (error.error?.detail == Constant.ERROR_EXISTS_NAME) {
            this.errorMessageList.push(this.getErrorMessage('exists-name', file[Constant.NAME_ELEMENT], null));
            return;
          }
          this.errorMessageList.push(
            Helper.formatString(this.translateService.instant(`simple-signage-editor.other-error`), file[Constant.NAME_ELEMENT])
          );
        }
      );
  }

  /**
   * Get error message from backward
   *
   * @param data
   * @param file
   * @returns
   */
  private getErrorMessageFromBackWard(data: SimpleMedia, file: any): string {
    let result = null;
    switch (data.id) {
      case ErrorCodeSimpleMedia.ERROR_MAX_LENGTH_MEDIA_CODE:
        result = Helper.formatString(
          this.translateService.instant('simple-signage-editor.length-file'),
          file[Constant.NAME_ELEMENT],
          `${Constant.MAX_LENGTH_MEDIA_NAME}`
        );
        break;
      case ErrorCodeSimpleMedia.ERROR_MAX_WIDTH_VIDEO_CODE:
        result = this.getErrorMessage('video-max-width', file[Constant.NAME_ELEMENT], String(this.simpleMediaValidator.videoWidth));
        break;
      case ErrorCodeSimpleMedia.ERROR_MAX_HEIGHT_VIDEO_CODE:
        result = this.getErrorMessage('video-max-height', file[Constant.NAME_ELEMENT], String(this.simpleMediaValidator.videoHeight));
        break;
      case ErrorCodeSimpleMedia.ERROR_MAX_BIT_RATE_VIDEO_CODE:
        result = this.getErrorMessage('video-max-bit-rate', file[Constant.NAME_ELEMENT], String(this.simpleMediaValidator.videoBitRate));
        break;
      case ErrorCodeSimpleMedia.ERROR_MAX_VIDEO_FRAME_RATE_CODE:
        result = this.getErrorMessage(
          'video-max-frame-rate',
          file[Constant.NAME_ELEMENT],
          String(this.simpleMediaValidator.videoFrameRate)
        );
        break;
      case ErrorCodeSimpleMedia.ERROR_OTHER_CODE:
        result = Helper.formatString(this.translateService.instant(`simple-signage-editor.other-error`), file[Constant.NAME_ELEMENT]);
        break;
      default:
        break;
    }
    return result;
  }

  /**
   * Validate data simple media in front end
   *
   * @param files
   * @returns
   */
  private async validateSimpleMediaInFrontEnd(files: any[]): Promise<string[]> {
    let errMessages = [];
    const typeMedias = [TypeMediaFileEnum.MP4, TypeMediaFileEnum.PNG, TypeMediaFileEnum.JPG, TypeMediaFileEnum.BMP];
    for (const file of files) {
      let typeName = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
      let fileName = file.name.slice(0, file.name.lastIndexOf('.'));
      // validate type file
      if (!typeMedias.includes(typeName)) {
        errMessages.push(
          Helper.formatString(this.translateService.instant('simple-signage-editor.format-file'), file[Constant.NAME_ELEMENT])
        );
        continue;
      }
      // validate special characters
      if (fileName.match(Constant.FORMAT) || fileName.includes('\\')) {
        errMessages.push(
          Helper.formatString(this.translateService.instant('simple-signage-editor.special-character'), file[Constant.NAME_ELEMENT])
        );
        continue;
      }
      // validate max length
      if (fileName.length > Constant.MAX_LENGTH_MEDIA_NAME) {
        errMessages.push(
          Helper.formatString(
            this.translateService.instant('simple-signage-editor.length-file'),
            file[Constant.NAME_ELEMENT],
            `${Constant.MAX_LENGTH_MEDIA_NAME}`
          )
        );
        continue;
      }
      // validate image
      if (typeName != TypeMediaFileEnum.MP4) {
        // validate image size
        if (file[Constant.SIZE_ELEMENT] / Constant.SIZE_1MB > this.simpleMediaValidator.imageSize) {
          errMessages.push(
            this.getErrorMessage('image-max-size', file[Constant.NAME_ELEMENT], String(this.simpleMediaValidator.imageSize))
          );
          continue;
        }
        // get image information(w, h)
        let imageInfo = await this.getImageInformation(file);
        if (!imageInfo) {
          errMessages.push(
            Helper.formatString(this.translateService.instant('simple-signage-editor.other-error'), file[Constant.NAME_ELEMENT])
          );
          continue;
        }
        // validate unsupported file format
        if (imageInfo[Constant.ERROR_ELEMENT]) {
          errMessages.push(
            Helper.formatString(this.translateService.instant('dialog-error.unsupported-file-format'), file[Constant.NAME_ELEMENT])
          );
          continue;
        }
        // validate image height
        if (imageInfo[Constant.HEIGHT_ELEMENT] > this.simpleMediaValidator.imageHeight) {
          errMessages.push(
            this.getErrorMessage('image-max-height', file[Constant.NAME_ELEMENT], String(this.simpleMediaValidator.imageHeight))
          );
          continue;
        }
        // validate image width
        if (imageInfo[Constant.WIDTH_ELEMENT] > this.simpleMediaValidator.imageWidth) {
          errMessages.push(
            this.getErrorMessage('image-max-width', file[Constant.NAME_ELEMENT], String(this.simpleMediaValidator.imageWidth))
          );
          continue;
        }
        // validate video
      } else {
        // validate video size
        if (file[Constant.SIZE_ELEMENT] / Constant.SIZE_1MB > this.simpleMediaValidator.videoSize) {
          errMessages.push(
            this.getErrorMessage('video-max-size', file[Constant.NAME_ELEMENT], String(this.simpleMediaValidator.videoSize))
          );
          continue;
        }
        // get video information
        let videoInfo = await this.getVideoInformation(file);
        if (!videoInfo) {
          errMessages.push(
            Helper.formatString(this.translateService.instant('simple-signage-editor.other-error'), file[Constant.NAME_ELEMENT])
          );
          continue;
        }
        // validate video duration
        if (videoInfo[this.DURATION_ELEMENT] > this.simpleMediaValidator.videoDuration * this.TIME_ONE_HOUR) {
          errMessages.push(
            this.getErrorMessage('video-max-duration', file[Constant.NAME_ELEMENT], String(this.simpleMediaValidator.videoDuration))
          );
          continue;
        }
      }
    }
    return errMessages;
  }

  /**
   * Get error message
   *
   * @param errorMessage
   * @param mediaName
   * @param errorValue
   * @returns
   */
  private getErrorMessage(errorMessage: string, mediaName: string, errorValue: string): string {
    return Helper.formatString(this.translateService.instant(`simple-signage-editor.${errorMessage}`), mediaName, errorValue);
  }

  /**
   * Get image information
   *
   * @param media
   * @returns
   */
  private getImageInformation(media: any): any {
    return new Promise((resolve, reject) => {
      try {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          const img = new Image();
          img.onload = () => {
            resolve({ width: img.width, height: img.height });
          };
          img.onerror = () => {
            resolve({ error: Constant.ERROR_ELEMENT });
          };
          img.src = fileReader.result as string;
        };
        fileReader.readAsDataURL(media);
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * Get video information
   *
   * @param file
   * @returns
   */
  private getVideoInformation(file: any): any {
    return new Promise((resolve, reject) => {
      try {
        var video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function() {
          window.URL.revokeObjectURL(video.src);
          return resolve({ duration: video.duration });
        };
        video.src = URL.createObjectURL(file);
      } catch (e) {
        reject(e);
      }
    });
  }
  /**
   * select folder
   * @param folder
   */
  public selectFolder(folder: Folder): void {
    if (this.folderSelected?.isEdit || this.isPreviewOn) {
      return;
    }
    this.isOriginalSelect = folder.name == Constant.NAME_FOLDER_ORIGINAL ? true : false;
    this.dataService.sendData([this.IS_SELECT_FOLDER_ORIGINAL, this.isOriginalSelect]);
    this.folderSelected = folder;
    this.isSelectedMediaOrFolder = true;
    this.isSelectedMediaOfSequence = true;
    this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedMediaOfSequence]);
    if (this.mediaSelected?.type == TypeMediaFileEnum.MP4) {
      (document.getElementById(this.VIDEO_SIMPLE_ID) as HTMLVideoElement)?.load();
    }
    this.clearPreview();
    this.mediaSelected = undefined;
    this.mediaOfSequenceSelected = undefined;
  }

  /**
   * add folder
   */
  public addFolder(): void {
    if (this.mediasOfFolder || this.isPreviewOn || this.folderSelected?.isEdit) {
      return;
    }
    let folder = new Folder(null, '');
    this.folders.push(folder);
    this.folderSelected = this.folders[this.folders.length - 1];
    this.folderSelected.isEdit = true;
    this.mediaSelected = undefined;
    this.calculateRowHeight(this.folders.length + this.medias.length);
    this.dataService.sendData([this.IS_EDIT_FOLDER, this.folderSelected.isEdit]);
    this.isChangedData = true;
  }

  /**
   * edit folder
   * @returns
   */
  private editFolder(): void {
    if (this.isPreviewOn || this.folderSelected?.isEdit) {
      return;
    }
    if (!this.folderSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('simple-signage-editor.error'),
          text: this.translateService.instant('simple-signage-editor.choose-folder')
        }
      });
      return;
    } else if (this.folderSelected.isEdit) {
      return;
    }
    this.folderSelected.isEdit = true;
    this.oldValueFolderName = this.folderSelected.name;
    this.dataService.sendData([this.IS_EDIT_FOLDER, this.folderSelected.isEdit]);
    this.isChangedData = true;
  }

  /**
   * save folder
   */
  public saveFolder(): void {
    if (this.isWaitingActionAPI) {
      return;
    }
    this.isWaitingActionAPI = true;
    if (!this.validateFolder()) {
      this.saveDataSuccess.emit(false);
      this.isWaitingActionAPI = false;
      return;
    }
    // validate exists folder
    this.simpleFolderMediaService.checkExistFolder(this.folderSelected.name, this.folderSelected.id).subscribe(
      data => {
        if (data || this.folderSelected.name == Constant.NAME_FOLDER_ORIGINAL) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('simple-signage-editor.error'),
              text: this.translateService.instant('simple-signage-editor.exists-folder')
            }
          });
          this.isWaitingActionAPI = false;
          return;
        }
        // get information to save data
        let folderToSave = _.cloneDeep(this.folderSelected);
        folderToSave.name = folderToSave.name;
        // save data
        this.simpleFolderMediaService.save(folderToSave).subscribe(
          data => {
            this.saveDataSuccess.emit(true);
            this.handleAfterSaveFolder(data, folderToSave);
          },
          error => {
            this.isWaitingActionAPI = false;
            this.saveDataSuccess.emit(false);
            this.handleErrorSaveData(error, this.translateService.instant('simple-signage-editor.exists-folder'));
          }
        );
      },
      error => {
        this.isWaitingActionAPI = false;
        this.saveDataSuccess.emit(false);
        Helper.handleError(error, this.translateService, this.dialogService);
      }
    );
  }

  /**
   * checkShowStartAt
   * @returns
   */
  public checkShowStartAt(mediaOfSequence: MediaOfSequence): boolean {
    //const mediaOfSequences = JSON.parse(this.playlistSelected?.sequence);
    if (mediaOfSequence.idMedia == null && this.mediaOriginalVideo?.includes(mediaOfSequence.name.trim().split('_')[0])) {
      return true;
    }
    return false;
  }

  /**
   * checkShowDuration
   * @returns
   */
  public checkShowDuration(nameMedia: string): boolean {
    return this.mediaOriginalVideo?.includes(nameMedia);
  }

  /**
   * handle Error Save Data
   */
  private handleErrorSaveData(error: any, msg: any): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: error.error?.detail == Constant.ERROR_EXISTS_NAME ? msg : this.translateService.instant('dialog-error.msg')
      }
    });
  }
  /**
   * validate folder
   * @returns
   */
  private validateFolder(): boolean {
    // max length of folder name
    const MAX_LENGTH_FOLDER_NAME = 64;
    // validate empty
    if (this.folderSelected?.name.trim() == Constant.EMPTY) {
      this.folderRef?.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('simple-signage-editor.error'),
          text: this.translateService.instant('simple-signage-editor.empty-folder')
        }
      });
      return false;
      // validate max length
    } else if (this.folderSelected?.name.length > MAX_LENGTH_FOLDER_NAME) {
      this.folderRef?.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('simple-signage-editor.error'),
          text: this.translateService.instant('simple-signage-editor.length-folder')
        }
      });
      return false;
    }
    return true;
  }

  /**
   * delete folder
   * @returns
   */
  public deleteFolder(): void {
    if (this.isPreviewOn || this.folderSelected?.isEdit) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: Helper.formatString(this.translateService.instant('simple-signage-editor.want-delete'), this.folderSelected.name),
          button1: this.translateService.instant('simple-signage-editor.yes'),
          button2: this.translateService.instant('simple-signage-editor.no')
        }
      },
      result => {
        if (result) {
          // get medias by folder id
          this.simpleMediaService.getMediasByFolderId(this.folderSelected.id).subscribe(
            data => {
              let mediasOfFolder = Helper.convertDataSimpleMedias(data, false);
              // delete folder
              this.simpleFolderMediaService.deleteFolder(this.folderSelected.id).subscribe(
                () => {
                  this.handleAfterDeleteFolder(mediasOfFolder);
                  this.isChangeMediaOfSequence = !this.checkDiffDataMediaOfSequences();
                  this.mediasOfFolder = null;
                  let selectedIndex = this.folders.findIndex(folder => folder.id == this.folderSelected.id);
                  if (selectedIndex != -1) {
                    this.folders.splice(selectedIndex, 1);
                    this.folderSelected = undefined;
                    if (this.folders?.length) {
                      this.selectFolder(this.folders[0]);
                    } else {
                      this.isSelectedMediaOrFolder = false;
                      this.isSelectedMediaOfSequence = false;
                      this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedMediaOfSequence]);
                    }
                  }
                },
                error => Helper.handleError(error, this.translateService, this.dialogService)
              );
            },
            error => Helper.handleError(error, this.translateService, this.dialogService)
          );
        }
      }
    );
  }

  /**
   * delete media
   */
  public deleteMedia(): void {
    if (!this.mediaSelected?.id || this.isPreviewOn || this.folderSelected?.isEdit) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: Helper.formatString(
            this.translateService.instant('simple-signage-editor.want-delete'),
            `${this.mediaSelected.name}.${this.mediaSelected.type}`
          ),
          button1: this.translateService.instant('simple-signage-editor.yes'),
          button2: this.translateService.instant('simple-signage-editor.no')
        }
      },
      result => {
        if (result) {
          // delete media
          this.simpleMediaService.deleteMedia(this.mediaSelected.id).subscribe(
            () => {
              // handle after delete media of folder
              if (this.mediasOfFolder) {
                // case search and delete
                let index = this.oldMediasOfFolder.findIndex(media => media.id == this.mediaSelected.id);
                if (index != -1) {
                  this.oldMediasOfFolder.splice(index, 1);
                }
                this.dispatchDataToStore(null, null, this.oldMediasOfFolder, null);
                // handle after delete medias of folder
                this.handleAfterDeleteMedia(this.mediasOfFolder);
                // select media
                this.selectMedia(this.mediasOfFolder.length ? this.mediasOfFolder[0] : undefined);
                this.calculateRowHeight(this.mediasOfFolder.length);
                if (!this.mediasOfFolder.length) {
                  this.isSelectedMediaOrFolder = false;
                  this.isSelectedMediaOfSequence = false;
                  this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedMediaOfSequence]);
                }
              } else {
                // handle after delete medias
                this.handleAfterDeleteMedia(this.medias);
                // case search and delete
                let oldMedias = this.miniMediaStateOfComponent?.medias;
                if (oldMedias?.length) {
                  let index = oldMedias.findIndex(media => media.id == this.mediaSelected.id);
                  if (index != -1) {
                    oldMedias.splice(index, 1);
                  }
                  this.dispatchDataToStore(this.miniMediaStateOfComponent?.folders ?? this.folders, oldMedias, null, null);
                }
                // select media
                this.selectMedia(this.medias.length ? this.medias[0] : undefined);
                if (!this.medias.length) {
                  this.isSelectedMediaOrFolder = false;
                  this.isSelectedMediaOfSequence = false;
                  this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedMediaOfSequence]);
                }
                this.calculateRowHeight(this.folders.length + this.medias.length);
              }
              this.isChangeMediaOfSequence = !this.checkDiffDataMediaOfSequences();
            },
            error => Helper.handleError(error, this.translateService, this.dialogService)
          );
        }
      }
    );
  }

  /**
   * handle after delete folder
   * @param mediasOfFolder
   */
  private handleAfterDeleteFolder(mediasOfFolder: Array<SimpleMedia>): void {
    if (!mediasOfFolder?.length) {
      return;
    }
    // get list media's id deleted
    let mediaIdsDeleted = mediasOfFolder.map(media => media.id);
    this.mediaOfSequences = this.mediaOfSequences?.filter(mediaOfSequence => !mediaIdsDeleted.includes(mediaOfSequence.idMedia));
    this.oldMediaOfSequences = this.oldMediaOfSequences.filter(item => !mediaIdsDeleted.includes(item.idMedia));
    this.calculateRowHeight(this.folders.length + this.medias.length);
  }

  /**
   * handle after delete media
   * @param medias
   */
  private handleAfterDeleteMedia(medias: Array<SimpleMedia>): void {
    let selectedIndex = medias.findIndex(media => media.id == this.mediaSelected.id);
    if (selectedIndex != -1) {
      this.mediaOfSequences = this.mediaOfSequences?.filter(mediaOfSequence => mediaOfSequence.idMedia !== medias[selectedIndex].id);
      this.oldMediaOfSequences = this.oldMediaOfSequences.filter(item => item.idMedia !== medias[selectedIndex].id);
      medias.splice(selectedIndex, 1);
    }
  }

  /**
   * delete item
   */
  public deleteItem(): void {
    if (this.isPreviewOn || this.isOriginalShow || this.isOriginalSelect) {
      return;
    }
    if (this.folderSelected) {
      this.deleteFolder();
    } else if (this.mediaSelected) {
      this.deleteMedia();
    }
  }

  /**
   * delete item in menu
   */
  private deleteItemInMenu(): void {
    if (this.isPreviewOn) {
      return;
    }
    if (this.folderSelected) {
      this.deleteFolder();
    } else if (this.mediaSelected) {
      this.deleteMedia();
    } else if (this.mediaOfSequenceSelected) {
      this.deleteMediaOfSequence();
    }
  }

  /**
   * select media
   * @param media
   */
  public selectMedia(media: SimpleMedia): void {
    if (this.isPreviewOn || this.folderSelected?.isEdit) {
      return;
    }
    if (media?.id == null) {
      this.isOriginalSelect = true;
    } else {
      this.isOriginalSelect = false;
    }
    this.dataService.sendData([this.IS_SELECT_FOLDER_ORIGINAL, this.isOriginalSelect]);
    this.isSelectedMediaOrFolder = true;
    this.isSelectedMediaOfSequence = true;
    this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedMediaOfSequence]);
    this.mediaSelected = media;
    this.folderSelected = undefined;
    this.mediaOfSequenceSelected = undefined;

    if (this.mediaSelected?.type == TypeMediaFileEnum.MP4) {
      this.pointPercents = [];
      this.pointPercents[0] = this.MAX_WIDTH_TIME_LINE;
      this.valueCursorPoint = 0;
      this.timeCursorPointSecond = 0;
      const totalDuration = Helper.convertDuration(this.mediaSelected?.duration);
      this.totalTimeVideo = Helper.formatDurationSimpleSignage(`${isNaN(totalDuration) ? 0 : totalDuration}`);
      this.isPreviewOn = false;
      this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
      (document.getElementById(this.VIDEO_SIMPLE_ID) as HTMLVideoElement)?.load();
    }
  }

  /**
   * Drop media
   * @param e CdkDragDrop
   */
  public dropMedia(e: CdkDragDrop<any[]>): void {
    if (!this.playlistSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('simple-signage-editor.error'),
          text: this.translateService.instant('simple-signage-editor.select-pl')
        }
      });
      return;
    } else if (this.playlistSelected?.isEdit) {
      return;
    }
    if (e.previousContainer === e.container) {
      if (!this.isDraggingMediaOfSequence) {
        return;
      }
      this.dropMediaOfSequence(e);
      this.handleAfterDragMediaSequence(e.currentIndex);
    } else {
      // convert data media
      const dataMedia =
        this.isTypeList || this.mediasOfFolder ? e.previousContainer.data[e.previousIndex] : this.miniMediaStateOfComponent?.mediaDragging;
      let mediaOfSequence = Helper.convertSimpleMediaToMediaOfSequence(dataMedia, this.mediaOriginalVideo);
      if (this.miniMediaStateOfComponent?.folderSelected) {
        mediaOfSequence.folderS3Name = this.miniMediaStateOfComponent?.folderSelected.folderS3Name;
      } else if (dataMedia.folderId != null) {
        const index = this.miniMediaStateOfComponent?.folders?.findIndex(item => item.id == dataMedia.folderId);
        if (index != -1) {
          mediaOfSequence.folderS3Name = this.miniMediaStateOfComponent?.folders[index].folderS3Name;
        }
      }
      this.mediaOfSequences?.splice(e.currentIndex, 0, mediaOfSequence);
      this.handleAfterDragMediaSequence(e.currentIndex);
    }
    this.isChangeMediaOfSequence = !this.checkDiffDataMediaOfSequences();
  }

  /**
   * handle after drag media sequence
   * @param currentIndex
   */
  private handleAfterDragMediaSequence(currentIndex: number) {
    this.handleDuration();
    this.calculatorPointList();
    this.selectMediaOfSequence(this.mediaOfSequences[currentIndex]);
  }

  /**
   * Handle duration value
   */
  private handleDuration(): void {
    const TIME_START = '00:00:00';
    this.totalSize = 0;
    let includesOriginal = false;
    this.mediaOfSequences?.forEach((media, index) => {
      if (media.idMedia == null && !includesOriginal && this.mediaOriginalVideo.includes(media.name)) {
        includesOriginal = true;
      }
      if (index == 0) {
        media.startTime = TIME_START;
      }
      this.totalSize = this.totalSize + media.size;
      if (index + 1 == this.mediaOfSequences?.length) {
        return;
      }
      const time = Helper.convertDuration(media.startTime ?? TIME_START);
      const startTimeNext = media.duration + time;
      this.mediaOfSequences[index + 1].startTime = Helper.formatDurationSimpleSignage(startTimeNext);
    });
    this.totalSize = _.round(this.totalSize / 1048576, 2);
    const mediaEnd = this.mediaOfSequences[this.mediaOfSequences?.length - 1];
    const totalDuration = Helper.convertDuration(mediaEnd?.startTime ?? TIME_START) + +mediaEnd?.duration;
    this.totalTime = Helper.formatDurationSimpleSignage(`${isNaN(totalDuration) ? 0 : totalDuration}`);
  }

  /**
   * Drop media in area sequence maker
   * @param event CdkDragDrop
   */
  public dropMediaOfSequence(event: CdkDragDrop<any[]>): void {
    if (this.isPreviewOn || this.folderSelected?.isEdit) {
      return;
    }
    let currentMediasOfSequence = event.container.data;
    let indexCurrent = event.currentIndex;
    let indexPrevious = event.previousIndex;
    moveItemInArray(currentMediasOfSequence, indexPrevious, indexCurrent);
  }

  /**
   * Allow Drop
   * @param e event
   */
  public allowDrop(e: any): void {
    if (this.isPreviewOn) {
      return;
    }
    e.preventDefault();
  }

  /**
   * select media of sequence
   * @param media
   */
  public selectMediaOfSequence(media: MediaOfSequence): void {
    if (this.isPreviewOn || this.folderSelected?.isEdit || this.playlistSelected?.isEdit) {
      return;
    }
    this.mediaOfSequenceSelected = media;
    if (this.mediaSelected?.type == TypeMediaFileEnum.MP4) {
      this.isPreviewOn = false;
      this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
      (document.getElementById(this.VIDEO_SIMPLE_ID) as HTMLVideoElement)?.load();
    }
    this.mediaSelected = undefined;
    this.folderSelected = undefined;
    this.calculatorPointList();
    const index = this.mediaOfSequences?.findIndex(media => media?.startTime == this.mediaOfSequenceSelected?.startTime);
    if (index == -1) {
      this.isSelectedMediaOfSequence = false;
    } else {
      this.isSelectedMediaOfSequence = true;
    }
    this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedMediaOfSequence]);
    this.valueCursorPoint = this.calculateCursorPoint(index);
    this.timeCursorPointSecond = Helper.convertDuration(this.mediaOfSequences[index]?.startTime);
    this.isSelectedMediaOrFolder = false;
    this.clearPreview();
    this.previewMediaOfSequence();
    this.isOriginalSelect = false;
    this.dataService.sendData([this.IS_SELECT_FOLDER_ORIGINAL, this.isOriginalSelect]);
  }

  /**
   * calculate cursor point
   * @param index
   * @returns
   */
  private calculateCursorPoint(index: number): number {
    let result = 0;
    if (index == 0) {
      return result;
    }
    for (let i = 0; i < index; i++) {
      result += this.pointPercents[i];
    }
    return result - 2;
  }

  /**
   * preview media of sequence
   */
  private previewMediaOfSequence(): void {
    let div = document.getElementById('preview') as HTMLElement;
    if (this.mediaOfSequenceSelected?.type == TypeMediaFileEnum.MP4) {
      div.appendChild(this.previewVideoSequence());
      if (this.isPreviewOn && this.isEnlargePreview) {
        this.playVideoSequence();
      }
    } else {
      div.appendChild(this.previewImageSequence());
    }
  }

  /**
   * clear preview
   */
  private clearPreview(): void {
    (document.getElementById(this.VIDEO_SEQ_ID) as HTMLVideoElement)?.remove();
    (document.getElementById(this.IMAGE_SEQ_ID) as HTMLVideoElement)?.remove();
  }

  /**
   * preview video sequence
   * @returns
   */
  private previewVideoSequence(): HTMLVideoElement {
    let video = document.createElement('video') as HTMLVideoElement;
    video.src = this.mediaOfSequenceSelected?.url;
    video.setAttribute('id', this.VIDEO_SEQ_ID);
    this.setStyleElement(video);
    video.load();
    return video;
  }

  /**
   * preview image sequence
   * @returns
   */
  private previewImageSequence(): HTMLImageElement {
    let img = document.createElement('img') as HTMLImageElement;
    img.src = this.mediaOfSequenceSelected?.url;
    img.setAttribute('id', this.IMAGE_SEQ_ID);
    this.setStyleElement(img);
    return img;
  }

  /**
   * set style element
   * @param element image or video
   */
  private setStyleElement(element: HTMLElement): void {
    element.style.width = '100%';
    element.style.height = '100%';
    element.style.objectFit = 'contain';
  }

  /**
   * play video sequence
   */
  private playVideoSequence(): void {
    (document.getElementById(this.VIDEO_SEQ_ID) as HTMLVideoElement)?.play();
  }

  /**
   * pause video sequence
   */
  private pauseVideoSequence(): void {
    (document.getElementById(this.VIDEO_SEQ_ID) as HTMLVideoElement)?.pause();
  }

  /**
   * fake timeline preview
   */
  private fakeTimelinePreview(): void {
    this.idInterval = setInterval(() => {
      this.timeCursorPointSecond++;
      if (this.timeCursorPointSecond >= Helper.convertDuration(this.totalTime)) {
        this.timeCursorPointSecond = Helper.convertDuration(this.totalTime);
        this.isPreviewOn = false;
        this.isSelectedMediaOfSequence = true;
        this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
        this.valueCursorPoint = this.MAX_WIDTH_TIME_LINE - this.CURSOR_POINT;
        clearInterval(this.idInterval);
        return;
      }
      const i = this.mediaOfSequences?.findIndex(media => media.randomNumber == this.mediaOfSequenceSelected.randomNumber);
      this.valueCursorPoint += this.pointPercents[i] / this.mediaOfSequenceSelected.duration;
      if (this.timeCursorPointSecond == Helper.convertDuration(this.mediaOfSequences[i + 1]?.startTime)) {
        clearInterval(this.idInterval);
        this.clearPreview();
        this.mediaOfSequenceSelected = this.mediaOfSequences[i + 1];
        this.playPreviewSequence();
      }
    }, 1000);
  }

  /**
   * change cursor point
   */
  public changeCursorPoint(): void {
    clearInterval(this.idIntervalMedia);
    let video = document.getElementById(this.VIDEO_SIMPLE_ID) as HTMLVideoElement;
    if (!video) {
      return;
    }

    video.currentTime = this.timeCursorPointSecond;
    if (this.isPreviewOn) {
      this.fakeTimelinePreviewMedia();
    }
  }

  /**
   * change cursor point of sequence
   */
  public changeCursorPointOfSequence(): void {
    clearInterval(this.idInterval);
    let mediaCurrent = this.mediaOfSequences?.find(
      media =>
        this.timeCursorPointSecond >= Helper.convertDuration(media.startTime) &&
        this.timeCursorPointSecond < Helper.convertDuration(media.startTime) + media.duration
    );
    let mediaLast = this.mediaOfSequences?.find(
      media => this.timeCursorPointSecond == Helper.convertDuration(media.startTime) + media.duration
    );
    let mediaCheck = mediaCurrent ?? mediaLast;
    if (mediaCheck) {
      this.mediaOfSequenceSelected = mediaCheck;
      this.clearPreview();
      let div = document.getElementById('preview') as HTMLElement;
      if (this.mediaOfSequenceSelected.type == TypeMediaFileEnum.MP4) {
        if (!document.getElementById(this.VIDEO_SEQ_ID)) {
          div.appendChild(this.previewVideoSequence());
        }
        let video = document.getElementById(this.VIDEO_SEQ_ID) as HTMLVideoElement;
        video.currentTime = mediaCurrent ? this.timeCursorPointSecond - Helper.convertDuration(mediaCurrent.startTime) : mediaLast.duration;
        this.isPreviewOn = mediaCurrent ? this.isPreviewOn : false;
        this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
        if (this.isPreviewOn && this.isEnlargePreview) {
          this.playVideoSequence();
        }
      } else {
        div.appendChild(this.previewImageSequence());
      }
      if (this.isPreviewOn) {
        this.fakeTimelinePreview();
      }
    }
  }

  /**
   * save sequence
   * @param isSelectOtherPlaylist
   */
  public saveSequence(isSelectOtherPlaylist: boolean): void {
    // validate
    if (this.isPreviewOn || !this.validateSequence()) {
      this.saveDataSuccess.emit(false);
      return;
    }
    let playlist: SimplePlaylist = _.cloneDeep(this.playlistSelected);
    playlist.sequence = this.handleSequenceBeforeSave();
    // save data
    this.simplePlaylistService.save(playlist).subscribe(
      () => {
        this.saveDataSuccess.emit(true);
        if (isSelectOtherPlaylist) {
          return;
        }
        this.handleAfterSaveSequence(playlist);
      },
      error => {
        this.saveDataSuccess.emit(false);
        Helper.handleError(error, this.translateService, this.dialogService);
      }
    );
  }

  /**
   * handle sequence before save
   * @returns string media of sequences
   */
  private handleSequenceBeforeSave(): string {
    let mediaOfSequences: Array<MediaOfSequence> = _.cloneDeep(this.mediaOfSequences);
    if (!mediaOfSequences?.length) {
      return null;
    }
    mediaOfSequences.forEach(media => {
      media.name = Helper.convertMediaNameBackWard(media.name, media.mediaNameEncode);
      const mediaUrl = `${media.name}.${media.type}`;
      media.url = media.folderS3Name ? `${media.folderS3Name}/${mediaUrl}` : mediaUrl;
      if (!this.isCheckedExpiration) {
        let mediaOld: MediaOfSequence = this.getMediaOfOldSequence(media.randomNumber);
        media.validFrom = mediaOld?.validFrom;
        media.validTo = mediaOld?.validTo;
      }
      if (!this.isCheckedPlaybackTime) {
        let mediaOld: MediaOfSequence = this.getMediaOfOldSequence(media.randomNumber);
        media.playTimeFrom = mediaOld?.playTimeFrom;
        media.playTimeTo = mediaOld?.playTimeTo;
      }
      if (media.playTimeTo == '00:00') {
        media.playTimeTo = '24:00';
      }
    });
    return JSON.stringify(mediaOfSequences);
  }

  /**
   * validate sequence
   * @returns
   */
  // validate duration
  private validateSequence(): boolean {
    if (!this.validateDurationMediaOfSequence()) {
      return false;
    }

    // validate finish date
    if (this.isCheckedExpiration && this.checkValidateTimeSequence(this.mediaOfSequences)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('simple-signage-editor.error'),
          text: this.translateService.instant('simple-signage-editor.invalid-date')
        }
      });
      return false;
    }

    // validate finish time
    if (this.isCheckedPlaybackTime && this.checkValidatePlaybackTime(this.mediaOfSequences)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('simple-signage-editor.error'),
          text: this.translateService.instant('simple-signage-editor.invalid-playback-time')
        }
      });
      return false;
    }

    // validate total time
    if (Helper.convertDuration(this.totalTime) > this.MAX_TOTAL_TIME) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('simple-signage-editor.error'),
          text: this.translateService.instant('simple-signage-editor.max-total')
        }
      });
      return false;
    }
    // validate total size
    let totalSize = 0;
    let mediaOfSequenceArray: Array<MediaOfSequence> = _.cloneDeep(this.mediaOfSequences);
    mediaOfSequenceArray.forEach(media => {
      totalSize += media.size;
    });
    if (totalSize > Constant.MAXIMUM_FILE_SIZE) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('simple-signage-editor.error'),
          text: this.translateService.instant('simple-signage-editor.maximum-file-size-save')
        }
      });
      return false;
    }
    return true;
  }

  /**
   * validate duration media of sequence
   * @returns
   */
  private validateDurationMediaOfSequence(): boolean {
    if (this.mediaOfSequences?.some(media => media.type.toLocaleLowerCase() != this.MP4_TYPE && media.duration > this.TIME_ONE_HOUR)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('simple-signage-editor.error'),
          text: this.translateService.instant('simple-signage-editor.max-duration')
        }
      });
      return false;
    }
    if (this.mediaOfSequences?.some(media => this.checkDurationEmpty(media) || media.duration == 0)) {
      this.mediaOfSequences?.forEach(media => {
        media.isInValidTime = this.checkDurationEmpty(media) || media.duration == 0;
      });
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('simple-signage-editor.error'),
          text: this.translateService.instant('simple-signage-editor.invalid-duration')
        }
      });
      return false;
    }
    return true;
  }

  /**
   * check validate time sequence
   * @param mediaOfSequences
   * @returns true if valid time otherwise return false
   *
   */
  private checkValidateTimeSequence(mediaOfSequences: Array<MediaOfSequence>): boolean {
    const FORMAT_DATE_VALIDATE = 'YYYY-MM-DD HH:mm';
    return mediaOfSequences.some(media => {
      return (
        media.validTo &&
        (moment(media.validTo).isBefore(moment().format(FORMAT_DATE_VALIDATE)) || moment(media.validTo).isBefore(moment(media.validFrom)))
      );
    });
  }

  /**
   * handle input
   * @param mediaOfSequence
   */
  public handleInput(mediaOfSequence: MediaOfSequence): void {
    mediaOfSequence.isEdit = true;
    mediaOfSequence.duration = +mediaOfSequence.hour * this.TIME_ONE_HOUR + +mediaOfSequence.minute * 60 + +mediaOfSequence.second;
    this.handleDuration();
    this.selectMediaOfSequence(this.mediaOfSequenceSelected);
    this.isChangeMediaOfSequence = !this.checkDiffDataMediaOfSequences();
  }

  /**
   * update config for picker
   *
   * @param period
   */
  private updateConfig(): void {
    this.config = {
      showWeekNumbers: 0,
      format: this.FORMAT_DATE,
      firstDayOfWeek: 'su',
      unSelectOnClick: false,
      locale: Helper.getLocale(this.languageKey),
      hideOnOutsideClick: true
    };
    this.configTime = {
      format: this.FORMAT_TIME,
      unSelectOnClick: false,
      hideOnOutsideClick: true,
      showTwentyFourHours: true,
      minutesInterval: -10,
      hours24Format: 'HH'
    };
  }

  /**
   * delete media of sequence
   */
  private deleteMediaOfSequence(): void {
    if (this.isPreviewOn) {
      return;
    }
    const index = this.mediaOfSequences?.findIndex(media => media.randomNumber == this.mediaOfSequenceSelected.randomNumber);
    if (index == -1) {
      return;
    }
    this.mediaOfSequences?.splice(index, 1);
    this.selectMediaOfSequence(this.mediaOfSequences[0]);
    this.handleDuration();
    this.calculatorPointList();
    this.isChangeMediaOfSequence = !this.checkDiffDataMediaOfSequences();
  }

  /**
   * cancel sequence
   */
  public cancelSequence(): void {
    if (this.isPreviewOn) {
      return;
    }
    this.mediaOfSequences = _.cloneDeep(this.oldMediaOfSequences);
    this.selectMediaOfSequence(this.mediaOfSequences[0]);
    this.handleDuration();
    this.calculatorPointList();
    this.isChangeMediaOfSequence = !this.checkDiffDataMediaOfSequences();
    this.isCancelSequence = true;
  }

  /**
   * enlarge preview
   */
  public enlargePreview(): void {
    this.isEnlargePreview = !this.isEnlargePreview;
    this.redrawMedia(this.isEnlargePreview);
  }

  /**
   * redraw media (choose tab, enlarge preview)
   * @param isRedraw
   * @returns
   */
  private redrawMedia(isRedraw: boolean): void {
    if (isRedraw) {
      if (this.mediaOfSequenceSelected) {
        clearInterval(this.idInterval);
        this.clearPreview();
        this.previewMediaOfSequence();
        if (this.isPreviewOn && this.isEnlargePreview) {
          this.fakeTimelinePreview();
        }
      } else if (this.mediaSelected) {
        if (this.isPreviewOn && this.isEnlargePreview) {
          clearInterval(this.idIntervalMedia);
          this.clearPreviewMedia();
          this.playVideo();
          this.fakeTimelinePreviewMedia();
        } else {
          this.selectMedia(this.mediaSelected);
        }
      }
    } else {
      this.isPreviewOn = false;
      this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
      if (this.mediaOfSequenceSelected) {
        clearInterval(this.idInterval);
        this.pauseVideoSequence();
        this.resetCursorPoint();
      } else {
        this.pauseVideo();
      }
    }
  }

  /**
   * reset cursor point
   */
  private resetCursorPoint(): void {
    if (this.timeCursorPointSecond >= Helper.convertDuration(this.totalTime)) {
      this.timeCursorPointSecond = Helper.convertDuration(this.totalTime);
      this.valueCursorPoint = this.MAX_WIDTH_TIME_LINE - this.CURSOR_POINT;
    } else {
      this.timeCursorPointSecond = Helper.convertDuration(this.mediaOfSequenceSelected.startTime);
      const index = this.mediaOfSequences?.findIndex(media => media.randomNumber == this.mediaOfSequenceSelected.randomNumber);
      this.valueCursorPoint = this.calculateCursorPoint(index);
    }
  }

  /**
   * reset play sequence
   */
  public resetPlaySequence(): void {
    this.isPreviewOn = false;
    this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
    this.clearPreview();
    // reset timeline preview
    this.timeCursorPointSecond = 0;
    this.valueCursorPoint = 0;
    clearInterval(this.idInterval);
    this.mediaOfSequenceSelected = this.mediaOfSequences[0];
    this.previewMediaOfSequence();
  }

  /**
   * previous media of sequence
   */
  public prevMediaOfSequence(): void {
    clearInterval(this.idInterval);
    const index = this.mediaOfSequences?.findIndex(media => media.randomNumber == this.mediaOfSequenceSelected.randomNumber);
    if (index == -1) {
      return;
    }
    if (this.mediaOfSequenceSelected?.type == TypeMediaFileEnum.MP4) {
      let video = document.getElementById(this.VIDEO_SEQ_ID) as HTMLVideoElement;
      video.currentTime = 0;
    }
    if (index == 0) {
      this.mediaOfSequenceSelected = this.mediaOfSequences[0];
      this.handleDrawPreview(0);
      return;
    }
    const startTimeOfCurrentMedia = Helper.convertDuration(this.mediaOfSequenceSelected.startTime);
    const endTimeOfCurrentMedia = startTimeOfCurrentMedia + this.mediaOfSequenceSelected.duration;
    // preview previous media of sequence
    if (this.timeCursorPointSecond == startTimeOfCurrentMedia) {
      this.mediaOfSequenceSelected = this.mediaOfSequences[index - 1];
      this.handleDrawPreview(index - 1);
    } else if (this.timeCursorPointSecond > startTimeOfCurrentMedia && this.timeCursorPointSecond < endTimeOfCurrentMedia) {
      this.handleDrawPreview(index);
    }
  }

  /**
   * preview media
   */
  public previewMedia(isPlay: boolean): void {
    if (this.mediaOfSequenceSelected && !this.validateDurationMediaOfSequence()) {
      return;
    }
    clearInterval(this.idIntervalMedia);
    clearInterval(this.idInterval);
    this.isPreviewOn = isPlay;
    this.isSelectedMediaOfSequence = !isPlay;
    this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
    this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedMediaOfSequence]);
    if (isPlay) {
      if (this.mediaOfSequenceSelected) {
        this.playPreviewSequence();
      } else {
        this.playPreviewVideo();
      }
    } else {
      if (this.mediaOfSequenceSelected) {
        this.pauseSequence();
      } else {
        this.pauseVideo();
      }
    }
  }

  /**
   * play preview video
   */
  private playPreviewVideo(): void {
    let video = document.getElementById(this.VIDEO_SIMPLE_ID) as HTMLVideoElement;
    // if preview is ended
    if (this.timeCursorPointSecond >= Helper.convertDuration(this.mediaSelected?.duration)) {
      this.valueCursorPoint = 0;
      this.timeCursorPointSecond = 0;
      if (video) {
        video.currentTime = 0;
      }
    }
    this.playVideo();
    this.fakeTimelinePreviewMedia();
  }

  /**
   * check duration empty
   * @param media
   * @returns
   */
  private checkDurationEmpty(media: MediaOfSequence): boolean {
    return !`${media.hour}`?.trim().length || !`${media.minute}`?.trim().length || !`${media.second}`?.trim().length;
  }

  /**
   * play video
   */
  private playVideo(): void {
    let videoElement = document.getElementById(this.VIDEO_SIMPLE_ID) as HTMLVideoElement;
    videoElement.currentTime = this.timeCursorPointSecond;
    videoElement?.play();
    videoElement.onpause = () => {
      this.isPreviewOn = false;
      this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
    };
  }

  /**
   * play preview
   */
  private playPreviewSequence(): void {
    let video = document.getElementById(this.VIDEO_SEQ_ID) as HTMLVideoElement;
    // if preview is ended
    if (this.timeCursorPointSecond >= Helper.convertDuration(this.totalTime)) {
      this.timeCursorPointSecond = Helper.convertDuration(this.totalTime);
      this.valueCursorPoint = 0;
      this.timeCursorPointSecond = 0;
      this.mediaOfSequenceSelected = this.mediaOfSequences[0];
      if (video) {
        video.currentTime = 0;
      }
    }
    if (video && video.currentTime > 0) {
      this.playVideoSequence();
    } else {
      this.clearPreview();
      clearInterval(this.idInterval);
      this.previewMediaOfSequence();
    }
    this.fakeTimelinePreview();
  }

  /**
   * pause sequence on canvas
   */
  private pauseSequence(): void {
    clearInterval(this.idInterval);
    if (this.mediaOfSequenceSelected?.type == TypeMediaFileEnum.MP4) {
      let video = document.getElementById(this.VIDEO_SEQ_ID) as HTMLVideoElement;
      video.currentTime = this.timeCursorPointSecond - Helper.convertDuration(this.mediaOfSequenceSelected.startTime);
      this.pauseVideoSequence();
    }
  }

  /**
   * pause video
   * @param video
   */
  private pauseVideo(): void {
    clearInterval(this.idIntervalMedia);
    (document.getElementById(this.VIDEO_SIMPLE_ID) as HTMLVideoElement)?.pause();
    if (!this.isTabPlaylist || !this.isEnlargePreview) {
      (document.getElementById(this.VIDEO_SIMPLE_ID) as HTMLVideoElement)?.load();
    }
  }

  /**
   * next media of sequence
   */
  public nextMediaOfSequence(): void {
    this.clearPreview();
    const index = this.mediaOfSequences?.findIndex(media => media.randomNumber == this.mediaOfSequenceSelected.randomNumber);
    if (index == -1) {
      return;
    }
    if (index == this.mediaOfSequences?.length - 1) {
      this.mediaOfSequenceSelected = this.mediaOfSequences[this.mediaOfSequences?.length - 1];
      clearInterval(this.idInterval);
      this.clearPreview();
      this.valueCursorPoint = this.MAX_WIDTH_TIME_LINE - this.CURSOR_POINT;
      this.timeCursorPointSecond = Helper.convertDuration(this.totalTime);
      this.isPreviewOn = false;
      this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
      return;
    }
    this.mediaOfSequenceSelected = this.mediaOfSequences[index + 1];
    this.handleDrawPreview(index + 1);
  }

  /**
   * handle draw preview
   * @param point
   */
  private handleDrawPreview(index: number): void {
    this.valueCursorPoint = this.calculateCursorPoint(index);
    this.timeCursorPointSecond = Helper.convertDuration(this.mediaOfSequences[index].startTime);
    this.playPreviewSequence();
  }

  /**
   * calculator point list color pink of
   */
  private calculatorPointList(): void {
    this.pointPercents = [];
    this.pointNumbers = this.mediaOfSequences?.map(media => media.duration);
    this.pointNumbers.forEach((point, index) => {
      let percentPoint1 = this.pointNumbers[index] / Helper.convertDuration(this.totalTime);
      const width = Math.floor(this.MAX_WIDTH_TIME_LINE * percentPoint1);
      this.pointPercents[index] = width > 0 ? width : 1;
    });
  }

  /**
   * change display list folder/media
   * @param isTypeFolder
   */
  public changeDisplayListMedia(isTypeFolder: boolean): void {
    this.isTypeList = isTypeFolder;
  }

  /**
   * open folder media
   * @param folder
   */
  public openFolderMedia(folder: Folder): void {
    if (this.folderSelected?.isEdit || this.isPreviewOn) {
      return;
    }
    setTimeout(() => {
      this.store.dispatch(
        new SaveMiniMediaStateAction({
          folderSelected: this.miniMediaStateOfComponent?.folderSelected ?? folder,
          folders: null,
          medias: null,
          mediasOfFolder: null,
          mediaDragging: null
        })
      );
    });
    if (folder.id == this.folderOriginal.id) {
      this.getFileOriginalMedia();
    } else {
      this.isOriginalShow = false;
      // get data media of folder
      this.simpleMediaService.getMediasByFolderId(folder.id).subscribe(
        data => {
          this.searchInputValue = Constant.EMPTY;
          this.handleAfterGetMediasOfFolder(data);
          if (!data.length) {
            this.isSelectedMediaOrFolder = false;
            this.isSelectedMediaOfSequence = false;
            this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedMediaOfSequence]);
          }
        },
        error => Helper.handleError(error, this.translateService, this.dialogService)
      );
    }
  }

  /**
   * handle after get medias of folder
   * @param data
   */
  private handleAfterGetMediasOfFolder(data: any): void {
    this.mediasOfFolder = Helper.convertDataSimpleMedias(data, true).filter(media => !TypeMediasNotSupported.includes(media.type));
    this.oldMediasOfFolder = _.cloneDeep(this.mediasOfFolder);
    this.isActiveButtonBack = true;
    this.selectMedia(this.mediasOfFolder[0]);
    this.calculateRowHeight(this.mediasOfFolder.length);
  }

  /**
   * calculate row height
   * @param size
   */
  private calculateRowHeight(size: number): void {
    const MAX_ROWS = 7;
    const HEIGHT_ROW = 50;
    this.heightRowDisplayNone = size > MAX_ROWS ? '0' : `calc(35vh - ${size * HEIGHT_ROW}px)`;
  }

  /**
   * search by name
   * @param value search value
   */
  public searchMediaByName(value: string): void {
    this.searchInputValue = value;
    if (this.mediasOfFolder) {
      this.dispatchDataToStore(null, null, this.oldMediasOfFolder, null);
      if (this.searchInputValue.trim() == '') {
        this.handleSearchEmpty(true);
        return;
      }
      this.mediasOfFolder = this.oldMediasOfFolder.filter(media =>
        media.name.toLocaleLowerCase().includes(this.searchInputValue.toLocaleLowerCase())
      );
      this.selectMedia(this.mediasOfFolder[0]);
      if (!this.mediasOfFolder.length) {
        this.isSelectedMediaOfSequence = false;
        this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedMediaOfSequence]);
        this.isSelectedMediaOrFolder = false;
      }
      this.calculateRowHeight(this.mediasOfFolder.length);
    } else {
      this.store.dispatch(
        new SaveMiniMediaStateAction({
          folderSelected: null,
          folders: this.miniMediaStateOfComponent?.folders ?? this.folders,
          medias: this.miniMediaStateOfComponent?.medias ?? this.medias,
          mediasOfFolder: null,
          mediaDragging: null
        })
      );
      if (this.searchInputValue.trim() == '') {
        this.isActiveButtonBack = false;
        this.getDataMiniMedia();
        this.mediasOfFolder = null;
        this.store.dispatch(new ResetMiniMediaStateAction());
        return;
      }
      this.simpleMediaService.searchByName(this.searchInputValue).subscribe(data => {
        // get all folder medias
        this.simpleFolderMediaService.getAllFolderMedias().subscribe(dataFolder => {
          const folders = Helper.convertDataSimpleFolderMedias(dataFolder);
          if (this.originalObject) {
            folders.push(this.folderOriginal);
          }
          const medias = Helper.convertDataSimpleMedias(data, true).filter(media => !TypeMediasNotSupported.includes(media.type));
          this.folders = folders.filter(folder => folder.name.toLocaleLowerCase().includes(this.searchInputValue.toLocaleLowerCase()));
          this.medias = medias.filter(media => media.name.toLocaleLowerCase().includes(this.searchInputValue.toLocaleLowerCase()));
          if (this.folders && this.folders.length > 0) {
            this.selectFolder(this.folders[0]);
          } else {
            this.selectMedia(this.medias[0]);
          }
          this.isActiveButtonBack = true;
          if (!this.medias.length) {
            this.isSelectedMediaOfSequence = false;
            this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedMediaOfSequence]);
            this.isSelectedMediaOrFolder = false;
          }
          this.calculateRowHeight(this.folders.length + this.medias.length);
        });
      });
    }
  }

  /**
   * handle search empty
   * @param isOpenFolder
   */
  private handleSearchEmpty(isOpenFolder: boolean): void {
    if (this.mediaSelected?.type == TypeMediaFileEnum.MP4) {
      this.isPreviewOn = false;
      this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
      clearInterval(this.idIntervalMedia);
      (document.getElementById(this.VIDEO_SIMPLE_ID) as HTMLVideoElement)?.remove();
    }
    if (isOpenFolder) {
      this.mediasOfFolder = this.oldMediasOfFolder;
      this.dispatchDataToStore(this.folders, this.medias, null, null);
    } else {
      this.isActiveButtonBack = false;
      this.folders = this.miniMediaStateOfComponent?.folders;
      this.medias = this.miniMediaStateOfComponent?.medias;
      this.mediasOfFolder = null;
      this.store.dispatch(new ResetMiniMediaStateAction());
    }
    this.selectMedia(undefined);
  }

  /**
   * prevent drop media to input search
   * @param event
   */
  public preventDrop(event): void {
    event.preventDefault();
  }

  /**
   * dispatch content tab data to store (mini media)
   * @param folders
   * @param medias
   * @param mediasOfFolder
   */
  private dispatchDataToStore(
    folders: Array<Folder>,
    medias: Array<SimpleMedia>,
    mediasOfFolder: Array<SimpleMedia>,
    mediaDragging: SimpleMedia
  ): void {
    this.store.dispatch(
      new SaveMiniMediaStateAction({
        folderSelected: this.miniMediaStateOfComponent?.folderSelected ?? this.folderSelected,
        folders: folders,
        medias: medias,
        mediasOfFolder: mediasOfFolder,
        mediaDragging: mediaDragging
      })
    );
  }

  /**
   * handle button back
   */
  public async handleButtonBack(): Promise<void> {
    if (!this.isActiveButtonBack) {
      return;
    }
    this.isOriginalShow = false;
    this.dataService.sendData([this.IS_SHOW_FOLDER_ORIGINAL, this.isOriginalShow]);
    await this.getDataMiniMedia();
    this.mediasOfFolder = null;
    if (this.isPreviewOn) {
      this.dataService.sendData([this.IS_ON_PREVIEW, !this.isPreviewOn]);
      this.pauseVideo();
    }
    this.handleSetDataAfterBack();
    this.searchInputValue = '';
    this.calculateRowHeight(this.folders.length + this.medias.length);
  }

  /**
   * handle set data after back
   */
  private handleSetDataAfterBack(): void {
    this.isActiveButtonBack = false;
    if (this.mediaSelected?.type == TypeMediaFileEnum.MP4) {
      this.isPreviewOn = false;
      this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
      (document.getElementById(this.VIDEO_SIMPLE_ID) as HTMLVideoElement)?.remove();
    }
    if (this.folderSelected) {
      this.selectFolder(this.folderSelected);
    } else {
      if (this.folders?.length) {
        this.selectFolder(this.folders[0]);
      } else if (this.medias?.length) {
        this.selectMedia(this.medias[0]);
      }
    }
    this.store.dispatch(new ResetMiniMediaStateAction());
  }

  /**
   * cancel add playlist
   */
  public cancelAddPlaylist(): void {
    this.playlistSelected.isEdit = false;
    this.dataService.sendData([this.IS_EDIT_PLAYLIST, this.playlistSelected.isEdit]);
    this.isChangedData = false;
    if (!this.playlistSelected.id) {
      this.playlists.pop();
      if (this.playlists?.length) {
        this.selectPlaylist(this.playlists[0]);
      } else {
        this.playlistSelected = undefined;
      }
    } else {
      this.playlistSelected.name = this.oldValuePlaylistName;
      this.selectPlaylist(this.playlistSelected);
    }
  }

  /**
   * cancel add folder
   */
  public cancelAddFolder(): void {
    this.folderSelected.isEdit = false;
    this.dataService.sendData([this.IS_EDIT_FOLDER, this.folderSelected.isEdit]);
    this.isChangedData = false;
    if (!this.folderSelected.id) {
      this.folders.pop();
      if (this.folders?.length) {
        this.selectFolder(this.folders[0]);
      } else {
        this.folderSelected = undefined;
      }
    } else {
      this.folderSelected.name = this.oldValueFolderName;
      this.selectFolder(this.folderSelected);
    }
  }

  /**
   * handle mouse over
   * @param folder
   */
  public handleMouseOver(folder: Folder): void {
    this.clearAttributeOfFolder(this.folders.filter(item => item.id != folder.id));
    this.isShowPlaceholder = false;
    if (this.isDraggingMedia) {
      folder[this.IS_MOVING_CSS] = true;
      folder[this.IS_DRAGGING_MEDIA] = true;
      this.folderNameContains = folder.name;
    }
  }

  /**
   * handle mouse leave
   * @param folder
   */
  public handleMouseLeave(folder: Folder): void {
    if (!this.isDraggingMedia) {
      return;
    }
    delete folder[this.IS_MOVING_CSS];
    delete folder[this.IS_DRAGGING_MEDIA];
    this.folderNameContains = undefined;
    this.store.dispatch(new ResetMiniMediaStateAction());
  }

  /**
   * clear attribute of folder
   * @param folders
   */
  private clearAttributeOfFolder(folders: Array<Folder>): void {
    folders.forEach(data => {
      delete data[this.IS_MOVING_CSS];
      delete data[this.IS_DRAGGING_MEDIA];
    });
  }

  /**
   * drag ended
   * @param media
   */
  public dragEnded(media?: SimpleMedia): void {
    this.isDraggingMedia = false;
    this.folders.forEach(data => delete data[this.IS_MOVING_CSS]);
    if (media) {
      this.dispatchDataToStore(null, null, null, media);
    }
  }

  /**
   * drop media in folder (list)
   * @param e
   */
  public async dropMediaInFolderList(e: CdkDragDrop<any[]>) {
    // convert data media
    let mediaOfFolder = Helper.convertDataSimpleMedia(e.previousContainer.data[e.previousIndex], false);
    const folder = this.folders.find(item => item[this.IS_DRAGGING_MEDIA]);
    if (!folder || folder.name == Constant.NAME_FOLDER_ORIGINAL) {
      this.clearAttributeOfFolder(this.folders);
      return;
    }
    this.simpleMediaService.checkExistMediaUsed(mediaOfFolder.id).subscribe(data => {
      const index = this.mediaOfSequences?.findIndex(media => media.idMedia == mediaOfFolder.id);
      if (data || index != -1) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('simple-signage-editor.error'),
            text: this.translateService.instant('simple-signage-editor.drag-media-folder')
          }
        });
        return;
      }
      this.simpleMediaService.saveMediaDroppedInFolder(mediaOfFolder, folder?.id ?? null).subscribe(
        data => {
          delete folder[this.IS_DRAGGING_MEDIA];
          this.calculateRowHeight(this.folders.length + this.medias.length);
          this.medias.splice(e.previousIndex, 1);
          this.mediaSelected = undefined;
        },
        error => {
          if (error.error?.detail == Constant.ERROR_EXISTS_NAME) {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: this.getErrorMessage('exists-name', mediaOfFolder.name, null)
              }
            });
          }
        }
      );
    });
  }

  /**
   * drop media in folder (tile)
   */
  public dropMediaInFolderTile(): void {
    if (!this.miniMediaStateOfComponent?.mediaDragging) {
      return;
    }
    let mediaOfFolder = Helper.convertDataSimpleMedia(this.miniMediaStateOfComponent?.mediaDragging, false);
    const folder = this.folders.find(item => item[this.IS_DRAGGING_MEDIA]);
    if (!folder) {
      this.clearAttributeOfFolder(this.folders);
      return;
    }
    this.simpleMediaService.checkExistMediaUsed(mediaOfFolder.id).subscribe(data => {
      const index = this.mediaOfSequences?.findIndex(media => media.idMedia == mediaOfFolder.id);
      if (data || index != -1) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('simple-signage-editor.error'),
            text: this.translateService.instant('simple-signage-editor.drag-media-folder')
          }
        });
        return;
      }
      this.simpleMediaService.saveMediaDroppedInFolder(mediaOfFolder, folder?.id ?? null).subscribe(
        data => {
          delete folder[this.IS_DRAGGING_MEDIA];
          const mediaIndex = this.medias.findIndex(item => item.id == mediaOfFolder.id);
          if (mediaIndex == -1) {
            return;
          }
          this.medias.splice(mediaIndex, 1);
          this.store.dispatch(new ResetMiniMediaStateAction());
        },
        error => {
          if (error.error?.detail == Constant.ERROR_EXISTS_NAME) {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: this.getErrorMessage('exists-name', mediaOfFolder.name, null)
              }
            });
          }
        }
      );
    });
  }

  //=====tab calendar===========
  /**
   * get all devices
   */
  private getAllDeviceCalendars(): void {
    this.deviceService.getAllDevicesForSimple().subscribe((data: Array<GroupDevice>) => {
      if (!data?.length) {
        return;
      }
      this.groupDevices = data;
      if (this.groupDevices.length == 1 && this.groupDevices[0].name == null) {
        this.deviceCalendars = Helper.convertDevicesToDeviceCalendars(this.groupDevices[0].devices, this.commonObject);
        this.selectDeviceCalendar(this.deviceCalendars[0]);
        this.devicesCalendarDisplayOrigin = this.deviceCalendars;
      } else {
        this.groupDevices = this.groupDevices.filter(group => group?.devices.length);
        this.groupDevices.forEach(group => {
          group.deviceCalendars = Helper.convertDevicesToDeviceCalendars(group.devices, this.commonObject);
          this.devicesCalendarDisplayOrigin = this.devicesCalendarDisplayOrigin.concat(group.deviceCalendars);
        });
        this.selectDeviceCalendar(this.groupDevices[0].deviceCalendars[0]);
      }
    });
  }

  /**
   * open group
   * @param groupDevice
   */
  public openGroup(groupDevice: GroupDevice): void {
    groupDevice.isExpand = !groupDevice.isExpand;
  }

  /**
   * select deviceCalendar
   * @param {DeviceCalendar} deviceCalendar selected deviceCalendar
   * @param event event click from Dom
   *
   */
  public selectDeviceCalendar(deviceCalendar: DeviceCalendar, event?): void {
    if (!deviceCalendar || event?.target?.id?.startsWith('check-box')) {
      return;
    }
    if (this.selectedDay) {
      this.selectedDay = undefined;
    }
    this.selectedDeviceCalendar = deviceCalendar;
    if (
      this.deviceCalendars?.some(device => device.isChecked) ||
      this.groupDevices?.some(group => group?.deviceCalendars?.some(device => device.isChecked))
    ) {
      return;
    }
    this.setContentDayByDevice(this.selectedDeviceCalendar);
  }

  /**
   * show previous month
   */
  public showPrevMonth(): void {
    // return if < start date of device
    if (this.selectedYear == this.selectedDeviceCalendar.startDate.getFullYear()) {
      if (this.selectedMonth <= this.selectedDeviceCalendar.startDate.getMonth()) {
        this.isPreviousMonth = true;
        return;
      }
    }
    this.isPreviousMonth = false;
    this.isNextMonth = false;
    this.selectedMonth = this.selectedMonth <= 0 ? 11 : this.selectedMonth - 1;
    this.selectedYear = this.selectedMonth == 11 ? this.selectedYear - 1 : this.selectedYear;
    // get calendar by selected month and selected year
    this.contentDaysMonth = Helper.getCalendarsByMonthYear(
      this.selectedDeviceCalendar,
      this.selectedMonth,
      this.selectedYear,
      this.commonObject
    );
  }

  /**
   * select month
   * @param month any
   */
  public selectMonth(month: any): void {
    this.selectedMonth = +month;
    // get calendar by selected month and selected year
    this.contentDaysMonth = Helper.getCalendarsByMonthYear(
      this.selectedDeviceCalendar,
      this.selectedMonth,
      this.selectedYear,
      this.commonObject
    );
  }

  /**
   * show next month
   */
  public showNextMonth(): void {
    // return if > finish date of device
    let dateEnd = _.cloneDeep(this.selectedDeviceCalendar.startDate);
    dateEnd.setFullYear(dateEnd.getFullYear() + Constant.MAX_YEAR);
    dateEnd.setDate(dateEnd.getDate() - 1);
    if (this.selectedYear == this.selectedDeviceCalendar.startDate.getFullYear() + Constant.MAX_YEAR) {
      if (this.selectedMonth >= dateEnd.getMonth()) {
        this.isNextMonth = true;
        return;
      }
    }
    this.isNextMonth = false;
    this.isPreviousMonth = false;
    this.selectedMonth = this.selectedMonth >= 11 ? 0 : this.selectedMonth + 1;
    this.selectedYear = this.selectedMonth == 0 ? this.selectedYear + 1 : this.selectedYear;
    // get calendar by selected month and selected year
    this.contentDaysMonth = Helper.getCalendarsByMonthYear(
      this.selectedDeviceCalendar,
      this.selectedMonth,
      this.selectedYear,
      this.commonObject
    );
  }

  /**
   * select day
   * @param {ContentDay} day selected day
   */
  public selectDay(day: ContentDay): void {
    if (day.isOtherMonth || day.inactive) {
      return;
    }
    this.selectedDay = day;
    if (this.selectedDay.playlistSimple) {
      this.selectedDay.playlistSimple = this.playlists.find(playlist => playlist.id == this.selectedDay.playlistSimple.id);
    }
  }

  /**
   * set repeat data calendar
   * @param day
   * @returns
   */
  public setRepeat(day: any): void {
    if (day.isOtherMonth || day.inactive) {
      return;
    }
    let devicesChecked = this.devicesCalendarDisplayOrigin.filter(device => device.isChecked);
    if (!devicesChecked.length) {
      this.setPlaylistRecurrence(day);
    } else {
      if (!devicesChecked.find(device => device.id == this.selectedDeviceCalendar.id)) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('simple-signage-editor.error'),
            text: this.translateService.instant('simple-signage-editor.check-selected-device')
          }
        });
        return;
      } else if (devicesChecked.length > 1) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('simple-signage-editor.multiple-devices-selected'),
              button1: this.translateService.instant('simple-signage-editor.yes'),
              button2: this.translateService.instant('simple-signage-editor.no')
            }
          },
          result => {
            if (result) {
              this.setPlaylistRecurrence(day);
            }
          }
        );
      } else {
        this.setPlaylistRecurrence(day);
      }
    }
  }

  /**
   * set playlist recurrence
   * @param day
   */
  private setPlaylistRecurrence(day: any): void {
    let deviceCalendarChange;
    if (this.indexFirstDeviceChecked > -1) {
      deviceCalendarChange = this.devicesCalendarDisplayOrigin[this.indexFirstDeviceChecked];
    } else {
      deviceCalendarChange = this.selectedDeviceCalendar;
    }

    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogPlaylistRecurrenceComponent,
      {
        data: {
          contentDay: Object.assign({}, day),
          startDate: deviceCalendarChange.startDate,
          device: deviceCalendarChange,
          calendars: deviceCalendarChange.calendarDays,
          screen: ScreenNameEnum.SIMPLE_SIGNAGE,
          isEdit: this.playlistSelected?.isEdit || this.mediaOfSequences?.some(media => media.isEdit)
        }
      },
      result => {
        this.isChangedData = false;
        if (!result) {
          return;
        }
        let contentDay = result[Constant.CONTENT_DAY_ELEMENT]; // content day after setting
        this.isUnlimitedDataFromDialogDataRecurrence = result[Constant.IS_UNLIMITED_ELEMENT];
        if (this.isUnlimitedDataFromDialogDataRecurrence) {
          this.isUnlimited = contentDay.playlistSimple ? true : false;
        }
        const date = Helper.getCurrentByTimezoneSetting(this.commonObject, false);
        const currentDate = Helper.getDateByDay(date.getFullYear(), date.getMonth() + 1, date.getDate());
        this.getUsedColors(currentDate);
        this.getUnUsedColors(currentDate);
        // One day
        if (!contentDay.isRepeated) {
          let contentDaySaved = this.setDataContentDay(contentDay, contentDay.fullDate, deviceCalendarChange);
          this.saveContentDays(deviceCalendarChange, new Array<ContentDay>(contentDaySaved));
          // Repeat
        } else {
          // repeat mode: Every Day
          if (contentDay.repeatMode == RepeatModeEnum.EVERY_DAY) {
            let contentDaysEveryDay = this.handleEveryDayMode(contentDay, deviceCalendarChange);
            if (this.isUnlimitedDataFromDialogDataRecurrence) {
              let oldContentDayData = this.updateOldContentDayData(contentDay.startDate, deviceCalendarChange);
              if (oldContentDayData?.length) {
                contentDaysEveryDay.push.apply(contentDaysEveryDay, oldContentDayData);
              }
            }
            this.saveContentDays(deviceCalendarChange, contentDaysEveryDay);
            // repeat mode: Every Week
          } else if (contentDay.repeatMode == RepeatModeEnum.EVERY_WEEK) {
            let listDay = [];
            // get day selected
            result[Constant.LIST_DAY_ELEMENT].forEach((day, index) => {
              // list day by week
              if (day[1]) {
                listDay.push(index);
              }
            });
            let contentDaysEveryWeek = this.handleEveryWeekMode(contentDay, listDay, deviceCalendarChange);
            this.saveContentDays(deviceCalendarChange, contentDaysEveryWeek);
          }
        }
        this.selectedDay = undefined;
        deviceCalendarChange.contentDays = deviceCalendarChange.calendarDays.filter(
          contentDay => contentDay.playlistSimple || contentDay.playlistSimpleId
        );
        this.contentDaysMonth.forEach(contentDay => {
          let index = deviceCalendarChange.contentDays.findIndex(content => content.fullDate.getTime() == contentDay.fullDate.getTime());
          if (index != -1) {
            contentDay.playlistSimple = deviceCalendarChange.contentDays[index].playlistSimple;
          }
        });
      }
    );
  }

  /**
   * get used colors
   * @param currentDate
   * @returns
   */
  private getUsedColors(currentDate: Date): void {
    // get color being used
    this.colorBeingUsed = new Map<Number, string>();
    this.selectedDeviceCalendar.contentDays?.forEach(contentDay => {
      if (contentDay.fullDate >= currentDate && contentDay.playlistSimple && !this.colorBeingUsed?.has(contentDay.playlistSimple.id)) {
        this.colorBeingUsed.set(contentDay.playlistSimple.id, contentDay.color);
      }
    });
  }

  /**
   * get unused colors
   * @param currentDate
   * @returns
   */
  private getUnUsedColors(currentDate: Date): void {
    let colors = new Array<string>();
    let playlistSimpleIds = new Set(
      this.selectedDeviceCalendar?.contentDays
        ?.filter(contentDay => contentDay.fullDate >= currentDate)
        ?.map(day => day?.playlistSimple?.id)
    );
    [...playlistSimpleIds].forEach(id => {
      const contentDay = this.selectedDeviceCalendar?.contentDays?.find(
        day => day.fullDate >= currentDate && day?.playlistSimple?.id == id && day?.color
      );
      if (contentDay) {
        colors.push(contentDay.color);
      }
    });

    let colorsOriginal: string[] = _.cloneDeep(this.colorsOriginal);
    while (colors.length >= this.MAX_NUMBER_COLORS) {
      if (!colorsOriginal.length) {
        colorsOriginal = _.cloneDeep(this.colorsOriginal);
      }
      let unUsedColorsOriginal: string[] = _.cloneDeep(colorsOriginal);
      unUsedColorsOriginal.forEach(color => {
        const index = colors.findIndex(c => c == color);
        if (index != -1) {
          colors.splice(index, 1);
          const indexColor = colorsOriginal.findIndex(c => c == color);
          if (indexColor != -1) {
            colorsOriginal.splice(indexColor, 1);
          }
        }
      });
    }
    // if there is an unused original colors
    if (colorsOriginal.length && colorsOriginal.length != this.colorsOriginal.length) {
      this.unUsedColors = colorsOriginal;
      // if using all colors
    } else if (!colors.length) {
      this.unUsedColors = _.cloneDeep(this.colorsOriginal);
    } else {
      let colorsOriginal: string[] = _.cloneDeep(this.colorsOriginal);
      colors.forEach(color => {
        const index = colorsOriginal.findIndex(c => c == color);
        if (index == -1) {
          return;
        }
        colorsOriginal.splice(index, 1);
      });
      this.unUsedColors = colorsOriginal;
    }
  }

  /**
   * Update old content day Data
   *
   * @param startDate
   * @param deviceCalendarChange
   * @returns
   */
  private updateOldContentDayData(startDate: Date, deviceCalendarChange: DeviceCalendar): ContentDay[] {
    let startContentDay = deviceCalendarChange.contentDays.find(
      content => content.unlimitedInfo && content.fullDate.getTime() == startDate.getTime()
    );
    if (!startContentDay) {
      return;
    }
    return deviceCalendarChange.contentDays
      .filter(data => data.fullDate.getTime() < startDate.getTime())
      .map(contentDay => {
        contentDay.unlimitedInfo = startContentDay.unlimitedInfo;
        return contentDay;
      });
  }

  /**
   * check or uncheck a device
   * @param index index of check-changed device
   * @param e
   */
  public changeCheckedDevice(index: number, e): void {
    e.stopPropagation();
    this.deviceCalendars[index].isChecked = !this.deviceCalendars[index].isChecked;
    this.isCheckedAll = this.deviceCalendars?.every(device => device.isChecked);
    this.getContentDayByFirstDeviceChecked();
  }

  /**
   * check or uncheck a device of group
   * @param device
   * @param e
   * @param group
   */
  public changeCheckedDeviceGroup(device, e, group: GroupDevice): void {
    e.stopPropagation();
    device.isChecked = !device.isChecked;
    group.isChecked = group.deviceCalendars.every(device => device.isChecked);
    this.isCheckedAll = this.groupDevices?.every(group => group.isChecked);
    this.getContentDayByFirstDeviceChecked();
  }

  /**
   * check or uncheck a group
   * @param index index of check-changed device
   * @param e
   */
  public changeCheckedGroup(index: number, e): void {
    e.stopPropagation();
    this.groupDevices[index].isChecked = !this.groupDevices[index].isChecked;
    this.groupDevices[index].deviceCalendars.forEach(device => (device.isChecked = this.groupDevices[index].isChecked));
    this.isCheckedAll = this.groupDevices?.every(group => group.isChecked);
    this.getContentDayByFirstDeviceChecked();
  }

  /**
   * check or uncheck all device
   */
  public checkAll(): void {
    this.isCheckedAll = !this.isCheckedAll;
    if (this.deviceCalendars) {
      this.deviceCalendars?.forEach(deviceData => (deviceData.isChecked = this.isCheckedAll));
    } else {
      this.groupDevices.forEach(group => {
        group.isChecked = this.isCheckedAll;
        group?.deviceCalendars?.forEach(device => {
          device.isChecked = this.isCheckedAll;
        });
      });
    }
    this.getContentDayByFirstDeviceChecked();
  }

  /**
   * delivery
   * @returns
   */
  private delivery(): void {
    // return if choose tab playlist
    if (this.isTabPlaylist) {
      return;
    }
    if (!this.devicesCalendarDisplayOrigin?.length) {
      return;
    }
    // open popup
    this.dialogService.showDialog(
      DialogDeliverySimpleComponent,
      {
        data: {
          groupDevices: _.cloneDeep(this.groupDevices)
        }
      },
      result => {}
    );
  }

  /**
   * save content days
   * @param contentDays list content days need to save
   *
   */
  private saveContentDays(deviceCalendarChange: DeviceCalendar, contentDays: Array<ContentDay>): void {
    if (!deviceCalendarChange) {
      return;
    }
    let listDeviceCalendarId = [];
    if (this.devicesCalendarDisplayOrigin.some(deviceCalendar => deviceCalendar.isChecked)) {
      listDeviceCalendarId = this.devicesCalendarDisplayOrigin
        .filter(deviceCalendar => deviceCalendar.isChecked)
        .map(deviceCalendar => deviceCalendar.id);
    } else {
      listDeviceCalendarId.push(this.selectedDeviceCalendar.id);
    }
    this.simplePlaylistContentDay
      .updateContentDaysOfDevice(
        Helper.convertDataSimpleContentDayBackward(contentDays, this.commonService.getCommonObject().setting),
        listDeviceCalendarId,
        this.isUnlimited
      )
      .subscribe(
        () => {
          this.toast.success(this.translateService.instant('common.save-success'), '');
          this.saveDataSuccess.emit(true);
          if (!this.isUnlimited) {
            if (this.indexFirstDeviceChecked > -1) {
              this.devicesCalendarDisplayOrigin[this.indexFirstDeviceChecked].contentDays.forEach(contentDay => {
                contentDay.unlimitedInfo = null;
              });
            } else {
              this.selectedDeviceCalendar.contentDays.forEach(contentDay => {
                contentDay.unlimitedInfo = null;
              });
            }
          }
        },
        error => {
          Helper.handleError(error, this.translateService, this.dialogService);
          this.saveDataSuccess.emit(false);
        }
      );
  }

  /**
   * set data content day
   * @param contentDay ContentDay
   * @param date Date
   * @param deviceCalendarChange deviceCalendar is setting calendar
   *
   */
  public setDataContentDay(contentDay: ContentDay, date: Date, deviceCalendarChange: DeviceCalendar): ContentDay {
    let indexRepeat = deviceCalendarChange.calendarDays.findIndex(calendarDay => calendarDay.fullDate.getTime() === date.getTime());
    if (!deviceCalendarChange.calendarDays[indexRepeat].playlistSimple && !contentDay.playlistSimple) {
      return deviceCalendarChange.calendarDays[indexRepeat];
    }
    deviceCalendarChange.calendarDays[indexRepeat].isDelivered = false;
    if (!contentDay.playlistSimple) {
      deviceCalendarChange.calendarDays[indexRepeat].color = '';
      deviceCalendarChange.calendarDays[indexRepeat].playlistSimple = new SimplePlaylist(-1);
      deviceCalendarChange.calendarDays[indexRepeat].playlistSimpleId = -1;
      deviceCalendarChange = this.handleDataOfUnlimitedDataInfo(indexRepeat, -1, contentDay, deviceCalendarChange);
      return deviceCalendarChange.calendarDays[indexRepeat];
    }
    deviceCalendarChange.calendarDays[indexRepeat].playlistSimple = Helper.convertDataSimplePlaylist(contentDay.playlistSimple);
    const id = deviceCalendarChange.calendarDays[indexRepeat].playlistSimple.id;
    if (this.colorBeingUsed?.has(id)) {
      deviceCalendarChange.calendarDays[indexRepeat].color = this.colorBeingUsed?.get(id);
      deviceCalendarChange.calendarDays[indexRepeat].playlistSimpleId = id;
      deviceCalendarChange = this.handleDataOfUnlimitedDataInfo(indexRepeat, id, contentDay, deviceCalendarChange);
      return deviceCalendarChange.calendarDays[indexRepeat];
    }
    const color = this.unUsedColors.shift();
    deviceCalendarChange.calendarDays[indexRepeat].color = color;
    this.colorBeingUsed.set(id, color);
    deviceCalendarChange.calendarDays[indexRepeat].playlistSimpleId = id;
    deviceCalendarChange = this.handleDataOfUnlimitedDataInfo(indexRepeat, id, contentDay, deviceCalendarChange);
    return deviceCalendarChange.calendarDays[indexRepeat];
  }

  /**
   * Handle data of unlimited data information
   *
   * @param indexRepeat
   * @param playlistSimpleId
   * @param contentDay
   * @param deviceCalendarChange
   */
  private handleDataOfUnlimitedDataInfo(
    indexRepeat: number,
    playlistSimpleId: Number,
    contentDay: ContentDay,
    deviceCalendarChange: DeviceCalendar
  ): DeviceCalendar {
    if (!this.isUnlimited) {
      deviceCalendarChange.calendarDays[indexRepeat].unlimitedInfo = null;
      return deviceCalendarChange;
    }
    if (this.isUnlimitedDataFromDialogDataRecurrence) {
      let unlimitedInfoObject = {};
      unlimitedInfoObject['playlistSimpleId'] = playlistSimpleId;
      unlimitedInfoObject['color'] = this.colorBeingUsed?.get(playlistSimpleId);
      unlimitedInfoObject['deadlineDate'] = Helper.convertDateToFormatDateSave(contentDay.finishDate, this.commonObject.setting);
      unlimitedInfoObject['isDelivered'] = false;
      deviceCalendarChange.calendarDays[indexRepeat].unlimitedInfo = unlimitedInfoObject;
    }
    return deviceCalendarChange;
  }

  /**
   * handle repeat mode: Every Day
   * @param contentDay content of day
   * @param deviceCalendarChange deviceCalendar is setting calendar
   *
   */
  private handleEveryDayMode(contentDay: any, deviceCalendarChange: DeviceCalendar): Array<ContentDay> {
    let results = new Array<ContentDay>();
    const endYear = contentDay.finishDate.getFullYear();
    const startYear = contentDay.startDate.getFullYear();
    if (endYear == startYear) {
      // if finish date same month
      if (contentDay.finishDate.getMonth() == contentDay.startDate.getMonth()) {
        for (let day = contentDay.startDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
          let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
          let contentDaySaved = this.setDataContentDay(contentDay, date, deviceCalendarChange);
          results.push(contentDaySaved);
        }
        // if finish date > start date (dif month)
      } else if (contentDay.finishDate.getTime() > contentDay.startDate.getTime()) {
        // get data repeat current month
        let daysInMonth = Helper.daysInMonth(contentDay.startDate);
        for (let day = contentDay.startDate.getDate(); day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
          let contentDaySaved = this.setDataContentDay(contentDay, date, deviceCalendarChange);
          results.push(contentDaySaved);
        }
        // get data repeat next month
        for (let month = contentDay.startDate.getMonth() + 1; month < contentDay.finishDate.getMonth(); month++) {
          let date = Helper.getDateByMonth(startYear, month + 1);
          let daysInMonth = Helper.daysInMonth(date);
          for (let day = 1; day <= daysInMonth; day++) {
            let date = Helper.getDateByDay(startYear, month + 1, day);
            let contentDaySaved = this.setDataContentDay(contentDay, date, deviceCalendarChange);
            results.push(contentDaySaved);
          }
        }
        // get data repeat end month
        let monthEndDate = Helper.getDateByMonth(startYear, contentDay.finishDate.getMonth() + 1);
        for (let day = monthEndDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
          let date = Helper.getDateByDay(endYear, monthEndDate.getMonth() + 1, day);
          let contentDaySaved = this.setDataContentDay(contentDay, date, deviceCalendarChange);
          results.push(contentDaySaved);
        }
      }
    } else if (endYear > startYear) {
      // get data repeat current month
      let daysInMonth = Helper.daysInMonth(contentDay.startDate);
      for (let day = contentDay.startDate.getDate(); day <= daysInMonth; day++) {
        let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
        let contentDaySaved = this.setDataContentDay(contentDay, date, deviceCalendarChange);
        results.push(contentDaySaved);
      }
      // get data repeat month of start date -> 12
      for (let month = contentDay.startDate.getMonth() + 1; month < 12; month++) {
        let date = Helper.getDateByMonth(startYear, month + 1);
        let daysInMonth = Helper.daysInMonth(date);
        for (let day = 1; day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(startYear, month + 1, day);
          let contentDaySaved = this.setDataContentDay(contentDay, date, deviceCalendarChange);
          results.push(contentDaySaved);
        }
      }
      if (endYear == startYear + Constant.MAX_YEAR) {
        for (let i = 1; i < Constant.MAX_YEAR; i++) {
          // get data repeat month of start date 1 -> 12 next year
          for (let month = 0; month < 12; month++) {
            let date = Helper.getDateByMonth(startYear + i, month + 1);
            let daysInMonth = Helper.daysInMonth(date);
            for (let day = 1; day <= daysInMonth; day++) {
              let date = Helper.getDateByDay(startYear + i, month + 1, day);
              let contentDaySaved = this.setDataContentDay(contentDay, date, deviceCalendarChange);
              results.push(contentDaySaved);
            }
          }
        }
      }
      // get data repeat month of end date previous end month
      for (let month = 1; month < contentDay.finishDate.getMonth() + 1; month++) {
        let date = Helper.getDateByMonth(endYear, month);
        let daysInMonth = Helper.daysInMonth(date);
        for (let day = 1; day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(endYear, month, day);
          let contentDaySaved = this.setDataContentDay(contentDay, date, deviceCalendarChange);
          results.push(contentDaySaved);
        }
      }
      // get data repeat end month
      let monthEndDate = Helper.getDateByMonth(endYear, contentDay.finishDate.getMonth() + 1);
      for (let day = monthEndDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
        let date = Helper.getDateByDay(endYear, monthEndDate.getMonth() + 1, day);
        let contentDaySaved = this.setDataContentDay(contentDay, date, deviceCalendarChange);
        results.push(contentDaySaved);
      }
    }
    return results;
  }

  /**
   * handle repeat mode: Every Week
   * @param contentDay content of day
   * @param listDay list day set playlist
   * @param deviceCalendarChange deviceCalendar is setting calendar
   *
   */
  private handleEveryWeekMode(contentDay: any, listDay: any, deviceCalendarChange: DeviceCalendar): Array<ContentDay> {
    let results = new Array<ContentDay>();
    const endYear = contentDay.finishDate.getFullYear();
    const startYear = contentDay.startDate.getFullYear();
    if (endYear == startYear) {
      // if finish date same month
      if (contentDay.finishDate.getMonth() == contentDay.startDate.getMonth()) {
        for (let day = contentDay.startDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
          let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            let contentDaySaved = this.setDataContentDay(contentDay, date, deviceCalendarChange);
            results.push(contentDaySaved);
          }
        }
        // if finish date > start date (dif month)
      } else if (contentDay.finishDate.getTime() > contentDay.startDate.getTime()) {
        // get data repeat current month
        let daysInMonth = Helper.daysInMonth(contentDay.startDate);
        for (let day = contentDay.startDate.getDate(); day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            let contentDaySaved = this.setDataContentDay(contentDay, date, deviceCalendarChange);
            results.push(contentDaySaved);
          }
        }
        // get data repeat next month
        for (let month = contentDay.startDate.getMonth() + 1; month < contentDay.finishDate.getMonth(); month++) {
          let date = Helper.getDateByMonth(startYear, month + 1);
          let daysInMonth = Helper.daysInMonth(date);
          for (let day = 1; day <= daysInMonth; day++) {
            let date = Helper.getDateByDay(startYear, month + 1, day);
            if (listDay.findIndex(index => date.getDay() == index) != -1) {
              let contentDaySaved = this.setDataContentDay(contentDay, date, deviceCalendarChange);
              results.push(contentDaySaved);
            }
          }
        }
        // get data repeat end month
        let monthEndDate = Helper.getDateByMonth(startYear, contentDay.finishDate.getMonth() + 1);
        for (let day = monthEndDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
          let date = Helper.getDateByDay(endYear, monthEndDate.getMonth() + 1, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            let contentDaySaved = this.setDataContentDay(contentDay, date, deviceCalendarChange);
            results.push(contentDaySaved);
          }
        }
      }
    } else if (endYear > startYear) {
      // get data repeat current month
      let daysInMonth = Helper.daysInMonth(contentDay.startDate);
      for (let day = contentDay.startDate.getDate(); day <= daysInMonth; day++) {
        let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
        if (listDay.findIndex(index => date.getDay() == index) != -1) {
          let contentDaySaved = this.setDataContentDay(contentDay, date, deviceCalendarChange);
          results.push(contentDaySaved);
        }
      }
      // get data repeat month of start date -> 12
      for (let month = contentDay.startDate.getMonth() + 1; month < 12; month++) {
        let date = Helper.getDateByMonth(startYear, month + 1);
        let daysInMonth = Helper.daysInMonth(date);
        for (let day = 1; day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(startYear, month + 1, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            let contentDaySaved = this.setDataContentDay(contentDay, date, deviceCalendarChange);
            results.push(contentDaySaved);
          }
        }
      }
      if (endYear == startYear + Constant.MAX_YEAR) {
        for (let i = 1; i < Constant.MAX_YEAR; i++) {
          // get data repeat month of start date 1 -> 12 next year
          for (let month = 0; month < 12; month++) {
            let date = Helper.getDateByMonth(startYear + i, month + 1);
            let daysInMonth = Helper.daysInMonth(date);
            for (let day = 1; day <= daysInMonth; day++) {
              let date = Helper.getDateByDay(startYear + i, month + 1, day);
              if (listDay.findIndex(index => date.getDay() == index) != -1) {
                let contentDaySaved = this.setDataContentDay(contentDay, date, deviceCalendarChange);
                results.push(contentDaySaved);
              }
            }
          }
        }
      }
      // get data repeat month of end date previous end month
      for (let month = 1; month < contentDay.finishDate.getMonth() + 1; month++) {
        let date = Helper.getDateByMonth(endYear, month);
        let daysInMonth = Helper.daysInMonth(date);
        for (let day = 1; day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(endYear, month, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            let contentDaySaved = this.setDataContentDay(contentDay, date, deviceCalendarChange);
            results.push(contentDaySaved);
          }
        }
      }
      // get data repeat end month
      let monthEndDate = Helper.getDateByMonth(endYear, contentDay.finishDate.getMonth() + 1);
      for (let day = monthEndDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
        let date = Helper.getDateByDay(endYear, monthEndDate.getMonth() + 1, day);
        if (listDay.findIndex(index => date.getDay() == index) != -1) {
          let contentDaySaved = this.setDataContentDay(contentDay, date, deviceCalendarChange);
          results.push(contentDaySaved);
        }
      }
    }
    return results;
  }

  /**
   * get content day in display
   */
  private getContentDayByFirstDeviceChecked(): void {
    let oldIndex = this.indexFirstDeviceChecked;
    this.indexFirstDeviceChecked = this.devicesCalendarDisplayOrigin.findIndex(deviceCalendar => deviceCalendar.isChecked);
    if (this.indexFirstDeviceChecked >= oldIndex && this.devicesCalendarDisplayOrigin[oldIndex]?.isChecked) {
      return;
    }
    if (this.indexFirstDeviceChecked > -1) {
      this.setContentDayByDevice(this.devicesCalendarDisplayOrigin[this.indexFirstDeviceChecked]);
    } else {
      this.setContentDayByDevice(this.selectedDeviceCalendar);
    }
  }

  /**
   * set content day for device
   * @param deviceCalendar
   */
  private setContentDayByDevice(deviceCalendar: DeviceCalendar): void {
    this.unUsedColors = _.cloneDeep(this.colorsOriginal);
    this.colorBeingUsed = new Map<Number, string>();
    this.deviceShowingCalendar = deviceCalendar;
    this.simplePlaylistContentDay.getContentDaysByDeviceId(deviceCalendar.id).subscribe(
      contentDaysData => {
        deviceCalendar.contentDays = contentDaysData.map(contentDayData => {
          return Helper.convertDataContentDay(contentDayData);
        });
        this.isUnlimited = this.selectedDeviceCalendar.contentDays
          .filter(contentDay => contentDay.fullDate >= new Date())
          .some(day => day.unlimitedInfo);
        if (deviceCalendar.contentDays?.length) {
          const colorSetFinal = deviceCalendar.contentDays[deviceCalendar.contentDays.length - 1].color;
          const index = this.colorsOriginal.indexOf(colorSetFinal);
          this.unUsedColors = _.cloneDeep(this.colorsOriginal).slice(index + 1);
        }
        deviceCalendar.calendarDays = Helper.getCalendars(deviceCalendar);
        deviceCalendar.contentDays.forEach(contentDay => {
          let date = Helper.getDateByDay(
            contentDay.fullDate.getFullYear(),
            contentDay.fullDate.getMonth() + 1,
            contentDay.fullDate.getDate()
          );
          let index = deviceCalendar.calendarDays.findIndex(content => content.fullDate.getTime() == date.getTime());
          if (index != -1) {
            deviceCalendar.calendarDays[index] = contentDay;
            this.colorBeingUsed.set(contentDay.playlistSimple?.id, contentDay.color);
          }
        });
        this.selectedMonth = new Date().getMonth();
        this.selectedYear = deviceCalendar.startDate.getFullYear();
        // get contentDays by month selected and year selected
        this.contentDaysMonth = Helper.getCalendarsByMonthYear(
          deviceCalendar,
          this.selectedMonth,
          deviceCalendar.startDate.getFullYear(),
          this.commonObject
        );
      },
      error => {
        this.handleError();
      }
    );
  }

  /**
   * open dialog simple sync setting
   * @return
   */
  private openDialogSimpleSyncSetting(): void {
    this.dialogService.showDialog(DialogSimpleSyncSettingComponent);
  }

  /**
   * handle error
   */
  private handleError(): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: this.translateService.instant('dialog-error.msg')
      }
    });
  }

  /**
   * fake timeline preview media
   */
  private fakeTimelinePreviewMedia(): void {
    this.idIntervalMedia = setInterval(() => {
      this.timeCursorPointSecond++;
      this.valueCursorPoint += this.pointPercents[0] / Helper.convertDuration(this.mediaSelected?.duration);
      if (this.timeCursorPointSecond >= Helper.convertDuration(this.totalTimeVideo)) {
        this.isPreviewOn = false;
        this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
        let video = document.getElementById(this.VIDEO_SIMPLE_ID) as HTMLVideoElement;
        if (video) {
          video.currentTime = video.duration;
        }
        clearInterval(this.idIntervalMedia);
        return;
      }
    }, 1000);
  }

  /**
   * clear preview media
   */
  private clearPreviewMedia(): void {
    this.isSelectedMediaOrFolder = true;
    this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedMediaOrFolder]);
    (document.getElementById(this.VIDEO_SIMPLE_ID) as HTMLVideoElement)?.load();
    this.timeCursorPointSecond = 0;
    this.valueCursorPoint = 0;
  }

  /**
   * change expiration
   */
  private changeExpiration(): void {
    this.isCheckedExpiration = !this.isCheckedExpiration;
    this.commonObject.isCheckedExpiration = this.isCheckedExpiration;
    this.store.dispatch(
      new SaveMainStateAction({
        common: this.commonObject
      })
    );
  }

  /**
   * change expiration
   */
  private changePlaybackTime(): void {
    this.isCheckedPlaybackTime = !this.isCheckedPlaybackTime;
    this.commonObject.isCheckedPlaybackTime = this.isCheckedPlaybackTime;
    this.store.dispatch(
      new SaveMainStateAction({
        common: this.commonObject
      })
    );
  }

  /**
   * get media of old sequence by randomNumber
   *
   * @param randomNumber
   * @returns
   */
  private getMediaOfOldSequence(randomNumber: number): any {
    return this.oldMediaOfSequences.find(media => media.randomNumber == randomNumber);
  }

  /**
   * calculate split point between media
   * @param index
   * @returns
   */
  public calculateSplitPoint(index: number): number {
    return index + 1 != this.pointPercents.length ? _.sumBy(this.pointPercents.slice(0, index + 1)) : this.MAX_WIDTH_TIME_LINE;
  }

  /**
   * check validate time playback time
   * @param mediaOfSequences
   * @returns true if valid time otherwise return false
   *
   */
  private checkValidatePlaybackTime(mediaOfSequences: Array<MediaOfSequence>): boolean {
    return mediaOfSequences.some(media => {
      return this.checkValidatePlaybackTimeOfSequence(media);
    });
  }

  /**
   * check validate playback time of sequence
   * @param media
   * @returns
   */
  private checkValidatePlaybackTimeOfSequence(media: MediaOfSequence): boolean {
    let valueFrom = Helper.convertTime(media.playTimeFrom);
    let valueTo = Helper.convertTime(media.playTimeTo);
    if (media.playTimeFrom == null || media.playTimeTo == null) {
      return;
    }
    if (valueFrom < 0) {
      return true;
    }
    if (valueFrom > 24 * 60 * 3600 || valueTo > 24 * 60 * 3600) {
      return true;
    }
    if (valueFrom > valueTo && valueTo != 0) {
      return true;
    }
    if (valueTo == valueFrom && valueTo != 0 && valueFrom != 0) {
      return true;
    }
  }

  /**
   * open dialog display time
   */
  public openDialogDisplayTime(index: any): void {
    this.dialogService.showDialog(
      DialogPlaybackTimeComponent,
      {
        data: {
          title: this.translateService.instant('dialog-dislay-time.title'),
          playTimeFrom: this.mediaOfSequences[index].playTimeFrom ? this.mediaOfSequences[index].playTimeFrom : Constant.TMP_TIME_NULL,
          playTimeTo: this.mediaOfSequences[index].playTimeTo ? this.mediaOfSequences[index].playTimeTo : Constant.TMP_TIME_NULL
        }
      },
      result => {
        if (!result) {
          return;
        }
        this.mediaOfSequences[index].playTimeFrom = result[0];
        this.mediaOfSequences[index].playTimeTo = result[1];
      }
    );
  }
  /**
   * checkIncludesOrigin
   * @returns
   */
  public checkIncludesOrigin(isTime: boolean): boolean {
    if (isTime) {
      return this.mediaOfSequences?.some(media => !media.idMedia && this.mediaOriginalVideo?.includes(media.name.trim().split('_')[0]));
    }
    return this.mediaOfSequences?.some(media => !media.idMedia);
  }

  /**
   * clear and search all
   */
  public clearAndSearchAll(): void {
    this.searchInputValue = Constant.EMPTY;
    if (this.mediasOfFolder) {
      this.dispatchDataToStore(null, null, this.oldMediasOfFolder, null);
      this.handleSearchEmpty(true);
    } else {
      this.getDataMiniMedia();
      this.dispatchDataToStore(
        this.miniMediaStateOfComponent?.folders ?? this.folders,
        this.miniMediaStateOfComponent?.medias ?? this.medias,
        null,
        null
      );
      this.isActiveButtonBack = false;
    }
  }

  /**
   * sendTimezoneToBack
   */
  private sendTimezoneToBack(): void {
    let timeZone = this.commonService
      .getCommonObject()
      .setting.timezone.name.split(' ')[0]
      .replace(')', '')
      .replace('(', '');
    this.simplePlaylistService.transferTimezoneToBack(timeZone).subscribe();
  }
}
